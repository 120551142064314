import { Component, Input } from '@angular/core';
import {
  ServiceRequest,
  SRStatusEnumModel,
} from '../../../../app/modules/admin/apps/service-request/service-request.types';
@Component({
  selector: 'app-service-request-item',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss'],
})
export class ServiceRequestItemComponent {
  @Input() serviceRequest: any;
  @Input() delegate: any;
  @Input() serviceRequestsLength: number;
  SRStatusEnumModel = SRStatusEnumModel;
}
