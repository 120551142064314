import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate, CanActivateChild, CanLoad, OnDestroy {
  userRoles: string[] = [];

  private subscription: Subscription = new Subscription();

  constructor(private _userService: UserService, private router: Router) {
    this.subscription.add(
      this._userService.user$.subscribe((user: User) => {
        this.userRoles = user.functional_roles;
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.checkChildRoutes(route, state)) {
      return true;
    } else {
      this.router.navigate(['/404-not-found']);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.checkChildRoutes(childRoute, state)) {
      return true;
    } else {
      this.router.navigate(['/404-not-found']);
      return false;
    }
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  checkChildRoutes(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    /************************** My Missions ***************************/
    if (state.url.includes('/apps/mission') && this.checkForRoles(route.data.roles)) {
      // Not authorized
      return false;
    } else if (state.url.includes('/apps/requests') && this.checkForRoles(route.data.roles)) {
      /************************** My Requests ***************************/
      // Not authorized
      return false;
    } else if (state.url.includes('/apps/missions') && this.checkForRoles(route.data.roles)) {
      /************************** Missions ***************************/
      // Not authorized
      return false;
    } else if (
      /************************** Initiated Requests ***************************/
      state.url.includes('/apps/initiated-requests') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Service Requests ***************************/
      state.url.includes('/apps/service-request') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Tasks ***************************/
      state.url.includes('/apps/tasks') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Completed Tasks ***************************/
      state.url.includes('/apps/completed-tasks') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Approval Requests ***************************/
      state.url.includes('/apps/approval-requests') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Bid Management ***************************/
      state.url.includes('/apps/bid-management') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Bid Management (Travel Agent) ***************************/
      state.url.includes('/apps/travel-agent') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Staff Management ***************************/
      state.url.includes('/apps/contacts') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else if (
      /************************** Settings ***************************/
      state.url.includes('/apps/settings') &&
      this.checkForRoles(route.data.roles)
    ) {
      // Not authorized
      return false;
    } else {
      return true;
    }
  }

  checkForRoles(roles: string[]) {
    if (roles && roles.length > 0) {
      return roles.every((permission) => {
        return !this.userRoles.includes(permission);
      });
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
