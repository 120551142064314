import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Directive({
  selector: '[tboRbacAllow]', // Role Base Access Control Allow
})
export class RbacDirective implements OnDestroy {
  allowedRoles: string[] = [];
  userRoles: string[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() set tboRbacAllow(allowedRoles: string[]) {

    this.allowedRoles = allowedRoles;

    this.showIfUserAllowed();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _userService: UserService
  ) {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.userRoles = user.functional_roles;
    });
  }

  public showIfUserAllowed(): void {
    if (this.userRoles === null || this.userRoles === undefined || this.userRoles.length === 0) {
      this.viewContainer.clear();
      return;
    }

    let allowed: boolean = false;

    if (this.allowedRoles && this.allowedRoles.length > 0) {
      allowed = this.userRoles.some((role) => this.allowedRoles.includes(role));
    }

    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

    return;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
