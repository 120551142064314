<!-- Course -->
<div class="flex flex-col h-auto shadow rounded-2xl overflow-hidden bg-card">
  <div class="flex flex-col">
    <div class="pr-2 pl-2">
      <!-- Course time -->
      <div class="flex flex-0 w-0 h-0" *cdkDragPreview></div>

      <!-- Task content -->
      <app-item-tasks [sr]="sr"></app-item-tasks>

      <div class="flex flex-col w-full mt-auto">
        <div class="relative h-0.5">
          <div
            class="z-10 absolute inset-x-0 h-6 -mt-3"
            [matTooltip]="
              (100 * serviceRequestsLength) / sr?.tasks?.length | percent
            "
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'-mb-0.5'"
          ></div>

          <mat-progress-bar
            class="h-0.5"
            [value]="(100 * serviceRequestsLength) / sr?.tasks?.length"
          ></mat-progress-bar>
        </div>

        <div class="px-6 py-4 text-right bg-gray-50 dark:bg-transparent">
          <a
            mat-stroked-button
            [routerLink]="['/apps/service-request/', sr?.id]"
          >
            <span class="inline-flex items-center">
              <ng-container>
                <span class="text-[#6067A3]">View</span>
              </ng-container>

              <mat-icon
                class="ml-1.5 icon-size-5"
                svgIcon="heroicons_solid:arrow-sm-right"
              ></mat-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
