import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {FuseAlertType} from "../../../../@fuse/components/alert";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../core/auth/auth.service";
import {User} from "../../../core/user/user.types";
import {UserService} from "../../../core/user/user.service";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignInDialogComponent implements OnInit {
    @ViewChild('signInNgForm') signInNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    signInForm: UntypedFormGroup;
    showAlert: boolean = false;
    savedHash: string;
    isVerified = false;

    private _authenticated: boolean = false;
    /**
     * Constructor
     */
    constructor(
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        private _activatedRoute: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _userService: UserService,
        private _router: Router
    ) {
    }

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for access token expiry
     */
    set accessTokenExpiry(token: string) {
        localStorage.setItem('accessTokenExp', token);
    }

    get accessTokenExpiry(): string {
        return localStorage.getItem('accessTokenExp') ?? '';
    }

    /**
     * Setter & getter for user details
     */
    set userDetails(token: string) {
        localStorage.setItem('user', token);
    }

    get userDetails(): string {
        return localStorage.getItem('user') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            // password  : ['', Validators.required],
            code: ['', []],
            rememberMe: ['']
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     */
    signIn(): void {
        // Return if the form is invalid
        if (this.signInForm.invalid) {
            return;
        }

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in
        this._authService.signIn(this.signInForm.value)
            .subscribe(
                () => {

                    // Set the redirect url.
                    // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
                    // to the correct page after a successful sign in. This way, that url can be set via
                    // routing file and we don't have to touch here.
                    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';

                    // Navigate to the redirect url
                    this._router.navigateByUrl(redirectURL);

                },
                (response) => {
                    // Re-enable the form
                    this.signInForm.enable();

                    // Reset the form
                    this.signInNgForm.resetForm();

                    // Set the alert
                    this.alert = {
                        type: 'error',
                        message: 'Wrong email or password'
                    };

                    // Show the alert
                    this.showAlert = true;
                }
            );
    }

    checkEmail() {
        this._authService.checkEmail(this.signInForm.controls.email.value)
            .subscribe((data: any) => {
                const response = data;
                console.log('response -------> ', response);
                if (response.hash) {
                    this.savedHash = response.hash;
                    this.isVerified = true;
                    this.signInForm.controls.code.setValidators(Validators.required);
                    this.signInForm.controls.code.updateValueAndValidity();
                }
                if (this.isVerified) {
                    this.signInForm.get(['email']).disable({onlySelf: true});
                }
            }, (e: HttpErrorResponse) => {
                 this.alert = {
                   type: 'error',
                   message: e.error?.message,
                 };

                 // Show the alert
                 this.showAlert = true;
            });
    }

    emailSignIn() {
        this._authService.emailSignIn(
        this.savedHash,
        this.signInForm.controls.code.value).subscribe((data: any) => {
            const response = data;
            console.log('response -------> ', response);
            if (data && data.token) {
                this.accessToken = data.token;
                this.accessTokenExpiry = data.exp.toString();

                this._authenticated = true;
                const _user = {} as User;
                _user.id = data.user._id;
                _user.email = data.user.email;
                _user.functional_roles = data.user.functional_roles;
                _user.name = `${data.user.firstname} ${data.user.lastname}`;
                _user.status = 'online';
                this.userDetails = JSON.stringify(_user);
                this._userService.user = _user;
                this._router.navigate(['/signed-in-redirect']);
                this.dialogRef.close();
            }

        });
    }
}
