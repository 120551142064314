<div
  class="flex flex-col h-[280px] max-h-full shadow rounded-2xl overflow-hidden bg-card"
>
  <div class="flex flex-col">
    <div
      class="text-lg font-bold text-left text-[#6067A3] h-10 pl-4 pr-2 mt-4 line-clamp-2 tooltip"
      [title]="mission?.title"
    >
      {{ mission.title }}
    </div>

    <div
      class="flex flex-col md:flex-row md:justify-between md:items-center pt-3"
    >
      <!-- start and end date -->
      <div>
        <!-- <div class="flex justify-between text-titleCardColor pr-6 pl-6 pt-3">
          <div class="gap-2">
            <div
              class="flex justify-center items-center text-sm font-semibold"
            >
              <img
                src="../../../assets/icons/mdi_calendar-arrow-right.svg"
                class="w-calendarwitdh pt-0.7 pr-1"
              />
              {{ mission.startsAt | date : "dd MMM yy" }}
            </div>
          </div>
        </div>
        <div class="flex justify-between text-titleCardColor pr-6 pl-6">
          <div class="gap-2">
            <div
              class="flex justify-center items-center text-sm font-semibold"
            >
              <img
                src="/assets/icons/mdi_calendar-arrow-left.svg"
                class="w-calendarwitdh pt-0.7 pr-1"
              />
              {{ mission.endsAt | date : "dd MMM yy" }}
            </div>
          </div>
        </div> -->
        <div class="flex gap-2 text-titleCardColor pl-4">
          <div class="flex gap-1 items-center text-sm font-semibold">
            <img
              src="/assets/icons/mdi_calendar-arrow-right.svg"
              class="w-calendarwitdh pt-0.7"
            />
            <p>
              {{ mission.startsAt | date : "dd MMM yy" }}&nbsp;&ndash;&nbsp;
              {{ mission.endsAt | date : "dd MMM yy" }}
            </p>
          </div>
          <!-- 
          <div class="flex items-center text-sm font-semibold">
            <img
              src="/assets/icons/mdi_calendar-arrow-left.svg"
              class="w-calendarwitdh pt-0.7 pr-1"
            />
          </div> 
          -->
        </div>
      </div>
    </div>
    <ng-container *ngFor="let location of mission.locations">
      <div class="flex justify-between text-titleCardColor pr-6 pl-4">
        <div class="gap-2">
          <div class="flex gap-1 justify-center items-center text-sm font-bold">
            <mat-icon
              class="w-calendarwitdh icon-size-4 text-titleCardColor"
              [svgIcon]="'heroicons_outline:location-marker'"
            >
            </mat-icon>
            {{ location.country }}-{{ location.city }}
          </div>
        </div>
      </div>
    </ng-container>

    <!-- 
    <div
      class="w-22 h-1 mt-1 pl-6 my-4 border-t-2 border-calendarColor mx-6 bg-[#6067A3]"
    ></div> 
    -->

    <!-- 
    <div class="flex flex-col">
      <div class="text-lg mt-1 font-bold text-cardTitleColor pl-4">
        Led By:
      </div>

      <div class="flex items-center pl-4 mt-6">
        <img
          class="w-7 h-7 rounded-full mr-2 border-2 ring-2 ring-white"
          src="assets/images/avatars/female-02.jpg"
          alt="Card cover image"
        />
        <div class="flex flex-col" *ngIf="missDelegate.isLead">
          <span 
            *ngFor="let missDelegate of mission.missionDelegates; let i = index"
            class="font-bold leading-none text-xs text-cardTitleColor"
          >
            <ng-container >
              {{missDelegate.delegate.firstname }} {{missDelegate.delegate.lastname }} 
            </ng-container>
          </span>
          <span class="text-xs leading-none mt-1">
            CLRE Middle <br />Officer
          </span>
        </div> 
      </div>
    </div> -->

    <div class="flex flex-col pl-4">
      <div class="text-lg mt-1 font-bold text-cardTitleColor">Delegates:</div>
      <div
        class="flex items-center mt-4 -space-x-1.5"
        *ngIf="this.mission.missionDelegates.length"
      >
        <ng-container
          *ngFor="
            let missDelegate of mission.missionDelegates.slice(0, 4);
            let i = index;
            let isFirst = first
          "
        >
          <ng-container
            *ngIf="
              i === 0 ||
              (missDelegate.delegate?.lastname?.charAt(0) !==
                mission.missionDelegates[i - 1]?.lastname?.charAt(0) &&
                missDelegate.delegate?.firstname?.charAt(0) !==
                  mission.missionDelegates[i - 1]?.firstname?.charAt(0))
            "
          >
            <div>
              <div
                class="flex items-center justify-center w-7 h-7 m-0.5 rounded-full text-xs ring-2 ring-white uppercase bg-[#6067A3] text-white dark:bg-gray-700 dark:text-gray-200"
                [ngClass]="{ ' bg-[#eab308]': isFirst }"
                matTooltip="{{ missDelegate.delegate.firstname }} {{
                  missDelegate.delegate.lastname
                }}"
              >
                {{ missDelegate.delegate?.firstname?.charAt(0)
                }}{{ missDelegate.delegate?.lastname?.charAt(0) }}
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="mission.missionDelegates.length > 4">
          <div
            class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring ring-offset-1 ring-bg-card ring-offset-transparent bg-gray-200 text-gray-500"
          >
            <div class="text-md font-semibold">
              +{{ mission.missionDelegates.slice(4).length }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Footer -->
  <div class="flex flex-col w-full mt-2">
    <div class="px-6 py-2 text-right bg-gray-50 dark:bg-transparent">
      <a mat-stroked-button [routerLink]="['/apps/mission/', mission.id]">
        <span class="inline-flex items-center">
          <ng-container>
            <span class="text-[#6067A3]">View Mission</span>
          </ng-container>
          <mat-icon
            class="ml-1.5 icon-size-4 fill-[#6067A3]"
            [svgIcon]="'heroicons_outline:arrow-sm-right'"
          >
          </mat-icon>
        </span>
      </a>
    </div>
  </div>
</div>
