import { Pipe, PipeTransform } from '@angular/core';
import { Comment } from 'app/modules/admin/apps/tasks/tasks.types';

/**
 * Finds an object from given source using the given key - value pairs
 */
@Pipe({
  name: 'truncate',
})
export class TextTruncatePipe implements PipeTransform {
  transform(text: string, length: number): string {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
  }
}
