import { FunctionalRoles } from 'app/core/user/user.types';
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    subtitle: 'Unique dashboard designs',
    type: 'group',
    icon: 'heroicons_outline:home',
    rbac: [FunctionalRoles.TRV_STAFF, FunctionalRoles.TRV_OFFICER],
    children: [
      {
        id: 'dashboards.cash-analytics',
        title: 'Dashboards',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/dashboards',
        rbac: [FunctionalRoles.TRV_STAFF, FunctionalRoles.TRV_OFFICER],
      },
    ],
  },
  {
    id: 'my-mission',
    title: 'My Activities',
    subtitle: '',
    type: 'group',
    icon: 'heroicons_outline:home',
    rbac: [FunctionalRoles.TRV_STAFF],
    children: [
      {
        id: 'workflow.missions',
        title: 'My Missions',
        type: 'basic',
        icon: 'feather:activity',
        link: '/apps/missions',
        rbac: [FunctionalRoles.TRV_STAFF],
      },
      {
        id: 'workflow.requests',
        title: 'My Requests',
        type: 'basic',
        icon: 'feather:command',
        link: '/apps/requests',
        rbac: [FunctionalRoles.TRV_STAFF],
      },
    ],
  },
  {
    id: 'workflow',
    title: 'Workflow',
    subtitle: 'Tasks, ...',
    type: 'group',
    icon: 'heroicons_outline:home',
    rbac: [FunctionalRoles.TRV_OFFICER, FunctionalRoles.TRV_AGENT, FunctionalRoles.TRV_SUPERVISOR],
    children: [
      {
        id: 'workflow.missions',
        title: 'Missions',
        type: 'basic',
        icon: 'feather:activity',
        link: '/apps/mission',
        rbac: [FunctionalRoles.TRV_OFFICER],
      },
      {
        id: 'workflow.initiated-requests',
        title: 'Initiated Requests',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/apps/initiated-requests',
        suffix: true,
        rbac: [FunctionalRoles.TRV_OFFICER],
      },
      {
        id: 'workflow.my-group-tasks',
        title: 'Service Requests',
        type: 'basic',
        icon: 'feather:command',
        link: '/apps/service-request',
        rbac: [FunctionalRoles.TRV_OFFICER],
      },
      {
        id: 'workflow.my-tasks',
        title: 'Tasks',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/apps/tasks',
        rbac: [FunctionalRoles.TRV_OFFICER],
      },
      {
        id: 'workflow.my-tasks',
        title: 'Tasks',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/apps/travel-agent-task',
        rbac: [FunctionalRoles.TRV_AGENT],
      },
      {
        id: 'workflow.completed-tasks',
        title: 'Completed Tasks',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/apps/completed-tasks',
        rbac: [FunctionalRoles.TRV_OFFICER],
      },
      {
        id: 'workflow.approval-requests',
        title: 'Approval Requests',
        type: 'basic',
        icon: 'feather:command',
        link: '/apps/approval-requests',
        rbac: [FunctionalRoles.TRV_SUPERVISOR],
      },
      {
        id: 'workflow.bid',
        title: 'Bid Management',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/apps/bid-management',
        rbac: [FunctionalRoles.TRV_OFFICER],
      },
      {
        id: 'workflow.bid-management',
        title: 'Bid Management',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/apps/travel-agent',
        rbac: [FunctionalRoles.TRV_AGENT],
      },
    ],
  },
  {
    id: 'administration',
    title: 'Administration',
    subtitle: 'Staff, Settings, ...',
    type: 'group',
    icon: 'heroicons_outline:home',
    rbac: [FunctionalRoles.TRV_AGENT],
    children: [
      {
        id: 'administration.staff',
        title: 'Staff Management',
        type: 'basic',
        icon: 'mat_solid:mark_email_read',
        link: '/apps/travel-agents',
        rbac: [FunctionalRoles.TRV_AGENT],
      },
    ],
  },
  {
    id: 'administration',
    title: 'Administration',
    subtitle: 'Staff, Settings, ...',
    type: 'group',
    icon: 'heroicons_outline:home',
    rbac: [FunctionalRoles.TRV_ADMIN],
    children: [
      {
        id: 'administration.staff',
        title: 'Staff Management',
        type: 'basic',
        icon: 'mat_solid:mark_email_read',
        link: '/apps/contacts',
        rbac: [FunctionalRoles.TRV_ADMIN],
      },
      {
        id: 'administration.swift-view',
        title: 'Settings',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/apps/settings',
        rbac: [FunctionalRoles.TRV_ADMIN],
      },
    ],
  },
];

export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    tooltip: 'Dashboards',
    type: 'aside',
    icon: 'heroicons_outline:home',
    children: [],
  },
  {
    id: 'apps',
    title: 'Apps',
    tooltip: 'Apps',
    type: 'aside',
    icon: 'heroicons_outline:qrcode',
    children: [],
  },
  {
    id: 'pages',
    title: 'Pages',
    tooltip: 'Pages',
    type: 'aside',
    icon: 'heroicons_outline:document-duplicate',
    children: [],
  },
  {
    id: 'user-interface',
    title: 'UI',
    tooltip: 'UI',
    type: 'aside',
    icon: 'heroicons_outline:collection',
    children: [],
  },
  {
    id: 'navigation-features',
    title: 'Navigation',
    tooltip: 'Navigation',
    type: 'aside',
    icon: 'heroicons_outline:menu',
    children: [],
  },
];

export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'DASHBOARDS',
    type: 'group',
    children: [],
  },
  {
    id: 'apps',
    title: 'APPS',
    type: 'group',
    children: [],
  },
  {
    id: 'others',
    title: 'OTHERS',
    type: 'group',
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'aside',
    icon: 'heroicons_outline:document-duplicate',
    children: [],
  },
  {
    id: 'user-interface',
    title: 'User Interface',
    type: 'aside',
    icon: 'heroicons_outline:collection',
    children: [],
  },
  {
    id: 'navigation-features',
    title: 'Navigation Features',
    type: 'aside',
    icon: 'heroicons_outline:menu',
    children: [],
  },
];

export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [],
  },
  {
    id: 'apps',
    title: 'Apps',
    type: 'group',
    icon: 'heroicons_outline:qrcode',
    children: [],
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'heroicons_outline:document-duplicate',
    children: [],
  },
  {
    id: 'user-interface',
    title: 'UI',
    type: 'group',
    icon: 'heroicons_outline:collection',
    children: [],
  },
  {
    id: 'navigation-features',
    title: 'Misc',
    type: 'group',
    icon: 'heroicons_outline:menu',
    children: [],
  },
];
