import { Contact } from 'app/layout/common/quick-chat/quick-chat.types';
import { Pagination } from 'app/shared/models/pagination.model';
import { Mission } from '../mission/mission.types';
import { Task } from '../tasks/tasks.types';

export interface ServicePayload {
  data: ServiceRequest[];
  pagination: Pagination;
}

export interface ServiceRequest {
  id?: string;
  amirId?: string;
  reference?: string;
  reasonForRejection?: string;
  amirReference?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  department?: string;
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
  closedAt?: Date;
  deletedAt?: Date;
  cancelledAt?: Date;
  mission?: Mission;
  staff?: Contact;
  tasks?: Tasks[];
  events?: any[];
  comments?: any[];
  note?: string;
  documents?: any[];
  assignedBy?: any;
  assignee?: any;
  costObjectType?: any;
  completedBy: any;
  categoryClassification?: string;
  chargeCostCenter?: boolean;
  title?: string;
  slug?: string;
  description?: string;
  missionType?: string;
  missionCategory?: string;
  category?: string;
  duration?: number;
  steps?: {
    order?: number;
    title?: string;
    subtitle?: string;
    content?: string;
  }[];
  totalSteps?: number;
  featured?: boolean;
  purpose?: string;
  progress?: {
    currentStep?: number;
    completed?: number;
  };
}

export interface Tasks {
  id?: string;
  reference?: string;
  code: null;
  name?: string;
  description?: null;
  notes: null;
  priority: null;
  dueDate: null;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  closedAt?: string;
  deletedAt: null;
  cancelledAt: null;
  events: [];
  assignee: null;
  documents: [];
}

export interface Staff {
  id?: string;
  name?: string;
  type?: string;
  title?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumbers?: null;
  location?: string;
  employeeId?: string;
  mobile: null;
  department?: string;
  departmentName?: string;
  grade?: string;
  tags: null;
  isExecutive: null;
  isHod?: null;
  isVip?: false;
  isPermanent?: boolean;
  isEmployee?: boolean;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  closedAt?: string;
  deletedAt?: null;
  category: {
    id?: string;
    name?: string;
    createdAt?: string;
    updatedAt?: string;
    closedAt?: string;
    deletedAt?: null;
  };
}

export enum SRStatusEnumModel {
  NEW = 'NEW',
}
