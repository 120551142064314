import { Pipe, PipeTransform } from '@angular/core';
import { Task, TaskStatus } from 'app/modules/admin/apps/tasks/tasks.types';

/**
 * Finds an object from given source using the given key - value pairs
 */
@Pipe({
  name: 'average',
  pure: false,
})
export class AveragePipe implements PipeTransform {
  /**
   * Constructor
   */
  constructor() {}

  /**
   * Transform
   *
   * @param value A string or an array of strings to find from source
   * @param key Key of the object property to look for
   * @param source Array of objects to find from
   */
  transform(tasks: Task[]): any {
    if (tasks.length === 0) {
      return null; // Handle empty array
    }

    const totalPercentage = tasks.reduce((acc, curr) => {
      if (
        curr.status === TaskStatus.COMPLETED ||
        curr.status === TaskStatus.COMPLETE
      ) {
        return acc + 100;
      }
      return acc; // For 'new' status, don't add anything to the total
    }, 0);

    const averagePercentage = totalPercentage / tasks.length;

    return averagePercentage;
  }
}
