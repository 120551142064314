import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
// import { SettingsService, StartupService, TokenService } from '@core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../core/user/user.types';
import { appConfig } from '../../../core/config/app.config';
import { OktaResponse } from 'app/shared/models/okta.model';
import { UserService } from '../../../core/user/user.service';
import { SSOAuthService } from '../../services/sso-auth.service';
import { OktaAppService } from '../../services/okta-app.service';
import { StaffSignUpComponent } from 'app/modules/auth/staff-sign-up/staff-sign-up.component';

@Component({
  template: '',
  providers: [OktaAppService],
})
export class CallbackComponent implements OnInit {
  private _authenticated: boolean = false;
  oktaAuth = new OktaAuth({
    clientId: appConfig.oidc.clientId,
    issuer: appConfig.oidc.issuer,
    redirectUri: appConfig.frontendUrl + appConfig.oidc.callback,
    tokenManager: {
      storage: 'sessionStorage',
    },
  });

  @Output() signInCompleted: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private okta: OktaAppService,
    private toastr: ToastrService,
    private _userService: UserService,
    private authService: SSOAuthService
  ) {}

  ngOnInit(): void {
    this.handleAuthentication();
  }

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token expiry
   */
  set accessTokenExpiry(token: string) {
    localStorage.setItem('accessTokenExp', token);
  }

  get accessTokenExpiry(): string {
    return localStorage.getItem('accessTokenExp') ?? '';
  }

  /**
   * Setter & getter for user details
   */
  set userDetails(token: string) {
    localStorage.setItem('user', token);
  }

  get userDetails(): string {
    return localStorage.getItem('user') ?? '';
  }

  async handleAuthentication(): Promise<any> {
    try {
      const tokens = await this.oktaAuth.token.parseFromUrl();
      const redirectURL = '/dashboards';
      console.log(tokens);
      const oktaData = { token: tokens };
      const isStaff = JSON.parse(localStorage.getItem('isStaff')) as boolean;

      return this.authService
        .oauth(oktaData)
        .pipe(catchError(async (err) => this.toastr.error(err)))
        .subscribe((data?: OktaResponse) => {
          console.log('data:', data);

          if (data && data.token) {
            if (isStaff) {
              this.toastr.success('Staff logged in successfully');
            } else {
              this.toastr.success('Travel officer logged in successfully');
            }

            if (isStaff) {
              this._userService.getUserByEmail(data.user.email).subscribe((response) => {
                if (response) {
                  this.setSession(data);
                  this.router.navigate([redirectURL]);
                } else {
                  this.setSession(data);
                  this.openCreateStaffDialog(data);
                }
              });
            } else {
              this.setSession(data);
              // localStorage.removeItem('isStaff')

              this.router.navigate([redirectURL]);
            }
          }
        });
    } catch (err) {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/sign-in']); // fka '/login'
    }
  }

  openCreateStaffDialog(data: OktaResponse) {
    const dialogRef = this.dialog.open(StaffSignUpComponent, {
      data: data,
      autoFocus: true,
      hasBackdrop: true,
      minWidth: '768px',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        /**
         * TODO:*
         * this.router.navigate([redirectURL])
         * localStorage.removeItem('isStaff')
         */
      }
    });
  }

  setSession(data: OktaResponse) {
    this._authenticated = true;
    this.accessToken = data.token;
    this.accessTokenExpiry = data.exp.toString();

    const _user = {
      id: data.user._id,
      email: data.user.email,
      functional_roles: data.user.functional_roles,
      name: `${data.user.firstname} ${data.user.lastname}`,
      status: 'online',
    } as User;

    this.userDetails = JSON.stringify(_user);
    this._userService.user = _user;
  }
}
