import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription, BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ApiResponse } from 'app/shared/models/response.model'; // Update path as necessary
import { environment } from 'assets/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountApiService implements OnDestroy {
  private baseUrl = environment.apiUrl;
  private pollingSubscription?: Subscription;

  // BehaviorSubject to hold and broadcast the count
  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();

  constructor(private http: HttpClient) {
    this.startPolling();
  }

  startPolling(): void {
    this.pollingSubscription = interval(5000)
      .pipe(switchMap(() => this.fetchCount()))
      .subscribe(
        (count) => this.countSubject.next(count), 
        (error) => console.error('Error fetching count:', error)
      );
  }

  fetchCount() {
    const status = 'SUBMITTED';
    return this.http
      .get<ApiResponse<number>>(`${this.baseUrl}/ms/api/v1/travel-services/service-request/count`, {
        params: { status },
      })
      .pipe(map((response) => response.count));
  }

  ngOnDestroy(): void {
    this.pollingSubscription?.unsubscribe();
  }
}
