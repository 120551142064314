import { Component, Input, OnInit } from '@angular/core';
import { Agent, Officer } from 'app/core/auth/auth.types';
import { Staff } from '../../../app/modules/admin/apps/service-request/service-request.types';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() user!: Agent | Officer | Staff | any;
  @Input() className!: string;
}
