/* eslint-disable */
export const nackCodes = [
    {
        id: "d11852f4-c1fd-45b2-a2d6-59bf8ce22d7e",
        code: "M50",
        translator_error: 901,
        error: "Message length exceeded"
    },
    {
        id: "44d4de5c-e73f-4bda-9914-fcc4ad74c83d",
        code: "M60",
        translator_error: 902,
        error: "Non-SWIFT character encountered (a character not included in the <X>, <Y>, <Z> character sets. Also see error code T32.)"
    },
    {
        id: "13d0997a-10a6-4c4c-bfde-0955570f074a",
        code: "T01",
        translator_error: 904,
        error: "Code word error. This check applies to:\nField 39B in MT700,705,707,710,720,740,747.\nField :22H:COAL subfield 3 in MT503,504."
    },
    {
        id: "9f68f8da-bab6-4ff6-9bed-78de223aaaf2",
        code: "T02",
        translator_error: 905,
        error: "Unable to determine the reason for NAK. Please contact your CSC immediately for advice."
    },
    {
        id: "39d1b29a-148e-41ce-8e9b-c125f31c234a",
        code: "T03",
        translator_error: 906,
        error: "Code word error. This check applies to:\nField 26C, subfield 3, in MT600,601,604,605,606,607,608,609.\nField 38B, subfield 1, in MT405.\nField :22H:COLA subfield 3 in MT503,504,505,506,507."
    },
    {
        id: "2181b45a-97b3-4d16-980e-53f7f30c35e9",
        code: "T04",
        translator_error: 907,
        error: "Code word error. This check applies to:\nField 26C, subfield 4, in MT600,601,604,605,606,607,608,609.\nField 38B, subfield 2, in MT405.\nField :22H:COLA subfield 3 in MT503,504,505,506,507.\nField :22H:REDE subfield 3 in MT528,529,536,537,548,575,578,584,586."
    },
    {
        id: "2bc74f57-2be5-4896-acb8-ee155c360018",
        code: "T05",
        translator_error: 908,
        error: "Code word error. This check applies to:\nField 68B, subfield 4, in MT609.\nField 38B, subfield 3, in MT405.\nField :22H:DEPO subfield 3 in MT503,504,505,506."
    },
    {
        id: "70b73f84-4a93-4ba1-bb48-24e2f5ebf8df",
        code: "T06",
        translator_error: 909,
        error: "Code word error. This check applies to:\nSubfield 1, field 32F\nSubfield 5, field 68B,68C\nSubfield 3, field 60F,60M,62F,62M,64,65 in MT608 or in any message appended in common groups n92,n95,n96."
    },
    {
        id: "b8184001-c139-491b-8e4a-f4b990f64035",
        code: "T07",
        translator_error: 910,
        error: "Code word error. This check applies to:\nSubfield 3, field 33G.\nField :22H:INOU subfield 3 in MT503,505,527,558."
    },
    {
        id: "0944485f-b08d-4426-b0c2-283560662304",
        code: "T08",
        translator_error: 911,
        error: "Code word error. This check applies to:\nField 23, in MT102_STP,609.\nField 26G, in MT550, or in any message appended in common groups n92,n95,n96.\nField 71A in MT100,101,102,103_not_STP,103_STP,104,107,405,740, or in any message appended in common groups n92,n95,n96.\nField :22H:REPR subfield 3 in MT527,558,569."
    },
    {
        id: "cc960ec3-b0d8-4efb-9d0c-759b1c69f802",
        code: "T09",
        translator_error: 912,
        error: "Code word error. This check applies to:\nSubfield 3, field 23 in MT305,601.\nSubfield 3, field 68C in MT609, or in any message appended in common groups n92,n95,n96.\nField :25D:COLL subfield 3 in MT507 when Data Source Scheme (DSS) is not present."
    },
    {
        id: "4046536a-0585-421a-9c28-9decf13d2d14",
        code: "T10",
        translator_error: 913,
        error: "The repetitive sequence occurred more than the maximum number of times permitted."
    },
    {
        id: "f4ef053c-9d27-4eed-895e-e5dcf9caf41d",
        code: "T11",
        translator_error: 914,
        error: "The repetitive sequence occurred less than the minimum number of times required."
    },
    {
        id: "678b44e0-d40c-40e0-9c99-22e92a50947c",
        code: "T12",
        translator_error: 915,
        error: "Field, line, subfield, or component content error. Or, the format Reject/Return is not allowed for field 72 in this MT. Or, when \"ISIN\" is used at the beginning of line one in field 35B it must never be composed of lower-case letters, nor a mix of upper and lower-case letters. Or, in the ISITC MT521 or MT523 in field 35B the second line is mandatory and must not begin with any of the code words defined for the third and subsequent lines."
    },
    {
        id: "d20eefd7-0ebb-4387-b137-0e53018db778",
        code: "T13",
        translator_error: 916,
        error: "The field tag is not expected at this location in this MT. Either a mandatory field is missing, the sequence of fields is not correct, the specified field is not allowed at this point in the MT, or the specified field is not a defined SWIFT field (for example, the field tag is invalid), an end-of-text sequence (CRLF-) was encountered when it was not expected, or more than one end-of-text sequence occurs in this message.\nOr in a common group message (i.e. n92,n95,n96) within the list of \"Copy of any field(s) of the original message\", there are generic fields and non-generic fields that are not allowed to be mixed in the same message.\nOr in one of the following ISO15022 messages: MT502, 503, 504, 505, 506, 507, 508, 509, 513, 514, 515, 518, 524, 527, 528, 529, 530, 535, 536, 537, 538, 540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 558, 564, 565, 566, 567, 568, 569, 575, 576, 578, 584, 586, 587, 588, 589, an optional sequence of fields was used, however a field or field qualifier which is required within that sequence is missing, or field 16R is present but its related code word is inappropriate.\nOr in a qualifier table, for a particular generic field, in a \"repeatable\" order, there is a list of \"OR\" qualifiers but more than one qualifier has been used in the repetitions of the generic field in this sequence."
    },
    {
        id: "477bfd96-f3c9-44eb-ae25-be3372b819ec",
        code: "T14",
        translator_error: 917,
        error: "Subfield[N] (negative indicator) must not be used when the amount, number, or number count component is equal to zero. This check applies to:\nField 19A in MT502,513-515,518,527-529,535-537,540-548,558,564,566-567, 569,575,578,584,586-588.\nField 32H in MT306, 320,330,340, 362,571.\nField 32N in MT646.\nField 33N in MT646,\nField 34E in MT320,330,341,571.\nField 34N in MT646\nField 35H in MT571,581.\nField 37G in MT320,330,362,644.\nField 37M in MT340,341,362,644.\nField 37R in MT341,360,361,362,644.\nFields 60A,62A,62B in MT572.\nField 60B in MT571,572.\nField 92A in MT502,506,513-515,518,527-529,540-547,558,564-566,568-569, 576,578,584,586-588.\nField 92E in MT564,566.\nField 93B in MT535,536,564-566,568.\nField 93C in MT535,564-566,568.\nField 93D in MT575.\nField 98D in MTs 564, 566.\nField 98E in MTs 513-515, 518, 528, 529, 540-548\n578, 586.Field 99A in MT506,513-515,518,528-529,535-536,540-547,569,575,578,586.\nAny of the above fields in common groups n92,n95,n96."
    },
    {
        id: "45399c05-d023-4a71-9adf-f071d78520e5",
        code: "T15",
        translator_error: 918,
        error: "Sign is not valid. <SIGN> must be either '+' or '-'."
    },
    {
        id: "5aa2a247-a75c-42e5-819c-ca3788081e5d",
        code: "T16",
        translator_error: 919,
        error: "Time offset is not valid. <OFFSET> has the same format as time <HHMM>."
    },
    {
        id: "b347b87c-148a-4739-b3e7-35a0fa799063",
        code: "T17",
        translator_error: 920,
        error: "Field, line, subfield, or component consists of blanks, <CRLF>, or it is missing a mandatory line, subfield, or component."
    },
    {
        id: "d26da257-83e8-4788-8e2c-80bc06a758d3",
        code: "T18",
        translator_error: 921,
        error: "Component is not in the format 3!n and/or it is not within the range 100-999. This check applies to:\nField 11R,11S: the first component must have the format 3!n and must be within the range 100-999.\nField 12, and MT105,106: this component must have the format 3!n and must be within the range 100-999.\nField 12, and MT NOT=(M105,106): this component must have the format 3!n.\nRefer to T88 for additional special exception checking.\nField 61: if the first character of subfield 6 is 'S' then the next three characters must have the format 3!n and must be within the range 100-999."
    },
    {
        id: "6cb7a9b3-3807-45e0-9ba6-918beac1ee31",
        code: "T19",
        translator_error: 922,
        error: "Code word error. This check applies to:\nSubfield 1 of field 87E,87F.\nField :25D:4!c//<Status> subfield 3 (Status) in MT507 when Data Source Scheme (DSS) is not present.\nField :22H:4!c//<Indicator> subfield 3 (Indicator) in MT 307,321."
    },
    {
        id: "2b28b810-2555-40a2-a03a-68b9800ca431",
        code: "T20",
        translator_error: 923,
        error: "Code word error in subfield 1, component 3, of field 32K or 33K."
    },
    {
        id: "667de94a-1c89-40dd-9ab3-200efa06a3b7",
        code: "T21",
        translator_error: 924,
        error: "(Available)."
    },
    {
        id: "0de77829-d976-4881-a069-20655b52eeb7",
        code: "T22",
        translator_error: 925,
        error: "A common reference mismatch exists between field 22, subfield 2, component 2 and one of the following:\nField 36 in MT305.\nField 33G subfield 2 in MT600.\nField 32B subfield 2 in MT601.\nOr, a common reference mismatch exists between field 22C, component 2, and one of the following:\nField 30P, YYMM of YYYYMMDD in MT360-362,364-365.\nField 36 sequence B in MT300.\nField 36 sequence B in MT303.\nField 36A sequence C in MT303.\nField 36 sequence D or field 37U sequence G in MT306.\nField 37G sequence B in MT320,330.\nField 37M sequence B in MT340,341.\nField 37J sequence B in MT350."
    },
    {
        id: "27560293-fa1d-4b34-abf4-2047afa52376",
        code: "T23",
        translator_error: 926,
        error: "Subfield 8 in field 61, subfield 5 in field 66A, or subfield 6 in field 26C is too long or contains only '//', or subfield 2 in fields 26A or 26B is too long or contains only '/'."
    },
    {
        id: "a2d4d33c-49f3-408d-84ff-ce3f1e622fdd",
        code: "T24",
        translator_error: 927,
        error: "Subfield 7 in field 61, subfield 4 in field 66A, subfield 5 in field 26C, subfield 1 in fields 26A or 26B is missing or is too long."
    },
    {
        id: "4079ba6b-d349-4cfa-a94d-9c7c47709acc",
        code: "T25",
        translator_error: 928,
        error: "Subfields 7 or 8 in field 61, subfield 4 or 5 in field 66A, subfield 5 or 6 in field 26C, subfield 1 or 2 in fields 26A or 26B has improper content."
    },
    {
        id: "32d7a452-926b-414b-a3e8-b347656fc83e",
        code: "T26",
        translator_error: 929,
        error: "Subfield 7 or 8 in field 61, subfield 4 or 5 in field 66A, subfield 5 or 6 in field 26C, subfield 1 or 2 in fields 26A or 26B has improper content.\nThis check applies to:\nfields 20,20C,21,21A,21F,21G,21P,21R."
    },
    {
        id: "e782e97f-663b-415c-a824-43056ad7170c",
        code: "T27",
        translator_error: 930,
        error: "BIC incorrectly formatted or invalid."
    },
    {
        id: "31049f2e-9361-4376-80a6-ef94c154df58",
        code: "T28",
        translator_error: 931,
        error: "SWIFT BIC is not a valid destination."
    },
    {
        id: "f38458e6-6112-46a5-987c-43ab9daf9022",
        code: "T29",
        translator_error: 932,
        error: "SWIFT BIC contains an invalid branch code."
    },
    {
        id: "d70dee3e-2264-4451-baf4-095db3faec5f",
        code: "T30",
        translator_error: 933,
        error: "Excessive lines, subfields, or components were found in this field."
    },
    {
        id: "2e1b2974-fa29-4f9f-a692-3f925dc639b4",
        code: "T31",
        translator_error: 934,
        error: "The line, subfield, or component separator or delimiter is missing or incorrect."
    },
    {
        id: "f9ef246b-b986-406a-b4a3-efaea7828895",
        code: "T32",
        translator_error: 935,
        error: "An expected subfield, component, or component separator was not found."
    },
    {
        id: "b3a8607a-07d2-4ecc-b275-2a2dfe9a9520",
        code: "T33",
        translator_error: 936,
        error: "The length of the field, line, subfield, or component contents is too long, or, the component consists of one or more hidden characters, or, the component consists of one or more imbedded characters which are inconsistent with the defined field format, or the characters do not belong to the correct character set."
    },
    {
        id: "a1b8425c-c963-4712-a8a5-ff654d8c64ef",
        code: "T34",
        translator_error: 937,
        error: "The length of the field, line, subfield, or component contents is too short."
    },
    {
        id: "a920c6e0-09eb-4236-a91d-65256dfd5a3e",
        code: "T35",
        translator_error: 938,
        error: "Code word error in subfield 1, field 26C."
    },
    {
        id: "1c340a53-abad-42c1-8b89-8543d0adc817",
        code: "T36",
        translator_error: 939,
        error: "Code word error. This check applies to:\nField 14A in MT360,361.\nField 14D in MT320,330,340,350,360,361.\nField 14J in MT360,361.\nField 17A in MT206,300,303,306,360,361,405.\nField 17B in MT500-505,513-515,518-519,527-529,535-538,540,547,558,564, 569,574W8BENO,574IRSLST,575-578,584,586-588.\nField 17F in MT304,306,340,360,361,405.\nField 17G,17N,17O in MT304.\nField 17T,17U in MT300.\nField 22A in MT293,300,303,304,306,320,330,340,341,350,360,361,362,364,365.\nField 23B in MT 103_not_STP,103_STP,303.\nField 94A in MT 300, 303, 304, 305, 306, 320, 330, 340, 341, 350, 360, 361, 362, 364, 365, 600, 601."
    },
    {
        id: "b5a5306c-3298-422c-8844-6f10dc49d44a",
        code: "T37",
        translator_error: 940,
        error: "Code word error. This check applies to:\nSubfield 2, field 35H when used with MT581 and with any message appended in common groups n92,n95,n96.\nSubfield 2, field 35T when used with MT552 and with any message appended in common groups n92,n95,n96."
    },
    {
        id: "96a088da-d9ff-499d-af7c-87c86dd6d9cb",
        code: "T38",
        translator_error: 941,
        error: "Illogical time specified."
    },
    {
        id: "45b9e0e8-0823-46fc-aa70-b2fcfa4b0c3e",
        code: "T39",
        translator_error: 942,
        error: "Time of UTC Indicator (HH[MM]) is not in the valid range (00<=HH<=23, 00<=MM<=59). This check applies to:\nField 98E in MTs 500-510, 513-515, 517, 518, 519, 524, 527, -530, 535-538, 540- 548, 558, 569, 574-IRSLST, 574-W8BENO, 575, 576, 578, 584, 586-589.\nField 98F in MTs 564."
    },
    {
        id: "4837a4cc-3cfd-41c3-a4f5-05afa86b1536",
        code: "T40",
        translator_error: 943,
        error: "Missing amount/number or incorrect amount/number first character."
    },
    {
        id: "1569a310-b293-4ccf-baf8-883acd9d071a",
        code: "T41",
        translator_error: 944,
        error: "Code word error in subfield 3, field 66A."
    },
    {
        id: "463b7bfb-7916-491a-b1e6-cfcbf96b1781",
        code: "T42",
        translator_error: 945,
        error: "Code word error in subfield 3, field 35U."
    },
    {
        id: "8527aa6f-91b6-469d-bb90-17aa37c44413",
        code: "T43",
        translator_error: 946,
        error: "The decimal separator in the amount/number subfield or component is missing, is not a valid character, or more than one separator is present."
    },
    {
        id: "edcffd75-bf31-4810-aaec-a961624a6701",
        code: "T44",
        translator_error: 947,
        error: "The SWIFT BIC exists but it is not enabled for FIN, or the BIC is active but it is not published in the current BIC directory, or it is not cutover."
    },
    {
        id: "fe7ad998-9879-4cf8-8f8a-7bd2e2ad42bf",
        code: "T45",
        translator_error: 948,
        error: "Invalid non-SWIFT BIC."
    },
    {
        id: "ca62e79e-e416-497c-99ad-3892c6563289",
        code: "T46",
        translator_error: 949,
        error: "A Test-and-Training destination must not be used in a LIVE message."
    },
    {
        id: "1d34b395-4fd3-433c-a127-99849a557fc5",
        code: "T47",
        translator_error: 950,
        error: "In an ERI field the data part of a code word /OCMT/, or /CHGS/ was being validated but the ending separator (such as, the third '/') is missing. This error is also a code word error that applies to:\nField 14G, subfield 1, in MT360-361.\nField 22, subfield 1, in MT305.\nField 23A, subfield 1, in MT360-362,364-365.\nField 23E. subfield 1, in MT101,103_not_STP,104,107,206,207,256,405,416.\nField 35B, in ISITC MT521,523: invalid or duplicated code word in line 3 or following.\nField 39P, subfield 1, in MT303.\nField 57D, line 1, in ISITC MT521,523.\nField 61, subfield 9, duplicated code words: /OCMT/ or /CHGS/.\nField 72 (narrative), duplicated code words: /OCMT/ or /CHGS/.\nField 72 (structured), duplicated code words: /OCMT/, /CHGS/, or /INS/.\nField 77A, duplicated code words: /OCMT/ or /CHGS/.\nField 72, in ISITC MT521,523: invalid or duplicated code word, or in ISITC MT523 mandatory code word is missing.\nField 77D, line 1, sequence C in MT303.\nField 77D, lines 1-6, in ISITC MT521,523: invalid or duplicated code word.\nField 77H, subfield 1, in MT306,340,360,361, 600, 601.\nField 79, duplicated code words: /OCMT/ or /CHGS/.\nField 85D, line 1, in ISITC MT521.\nField 86, duplicated code words: /OCMT/ or /CHGS/.\nField 87D, line 1, in ISITC MT521,523.\nField 88D, line 1, in ISITC MT521,523."
    },
    {
        id: "76b586d8-afb0-4173-8df9-e0369df1c478",
        code: "T48",
        translator_error: 951,
        error: "Code word error. This check applies to:\nField 14G, subfield 2, in MT360-361.\nField 22K, subfield 1, in MT306.\nField 23A, subfield 2, in MT360-362,364-365.\nField 23D, in MT340-341.\nField 23E, subfield 1, in MT103_STP.\nField 31P, subfield 2, in ISITC MT521,523.\nField 35B, in ISITC MT521,523: invalid code word in line 1.\nField 38E, subfield 2, MT360-361.\nField 38G, subfield 2 or 4, MT340,360-361.\nField 38H, subfield 2 or 4, MT360-361."
    },
    {
        id: "5eb2dfc1-2460-453f-ae64-69fe1c4f82d4",
        code: "T49",
        translator_error: 952,
        error: "(Available)."
    },
    {
        id: "a62e3ceb-2fac-4ef2-bc4f-635cfb6f2209",
        code: "T50",
        translator_error: 953,
        error: "Date error. Either Invalid Date subfield (this check applies to all MTs with a Date subfield) OR Invalid year <YY> in a Value Date subfield of the MTs that are candidate for the Value Date Ordering process.\nThe valid range for <YY> is:\n00-60 for the years 2000-2060\n80-99 for the years 1980-1999\nThis check applies to:\nField 30, in MTs: 101, 104, 107, 110, 111, 112, 201, 203, 204, 207, 210,\n256\nField 32A, in MTs: 102, 103, 110, 111, 112, 200, 202, 205, 256, 910."
    },
    {
        id: "cf4be429-6116-4200-8a25-2c8dd8e2d520",
        code: "T51",
        translator_error: 954,
        error: "Code word 'C','D','RC','RD','EC','ED' error."
    },
    {
        id: "b870fdb5-52c4-49a6-a16b-688fb0196c2d",
        code: "T52",
        translator_error: 955,
        error: "Invalid currency code or price code 'PCT','REN', or 'YLD'."
    },
    {
        id: "c095cf45-f604-44e0-aab2-cc82131955e9",
        code: "T53",
        translator_error: 956,
        error: "Code word error in subfield 6, component 1 of field 61."
    },
    {
        id: "d6ce555d-f8e6-42fe-9236-a996bad96e20",
        code: "T54",
        translator_error: 957,
        error: "The format of the first line of Field 50F (Party Identifier) is invalid. This check applies to:\nField 50F in MTs 101, 102, 102_STP, 103, 103_STP, 210, 910.\nThis check applies to all MTs containing field 50F, when appended to Common Group MTs n92, n95, n96."
    },
    {
        id: "a5db10dc-0e28-4c2c-9c85-4b193161de6e",
        code: "T55",
        translator_error: 958,
        error: "Code word error. This check applies to Subfield 1, component 1:\nField 50F in MTs 101, 102, 102_STP, 103, 103_STP, 210, 910.\nThis check applies to all MTs containing field 50F, when appended to Common Group MTs n92, n95, n96."
    },
    {
        id: "ecb494d6-1cec-4155-b094-a4b3891fe676",
        code: "T56",
        translator_error: 959,
        error: "Code word error. This check applies to Subfield 2, component 1:\nField 50F in MTs 101, 102, 102_STP, 103, 103_STP, 210, 910.\nThis check applies to all MTs containing field 50F, when appended to Common Group MTs n92, n95, n96."
    },
    {
        id: "d7d65df8-2fe4-41d6-b96c-45d2458df49f",
        code: "T57",
        translator_error: 960,
        error: "Code word error in subfield 2 of fields 31H, 31J, or 31X."
    },
    {
        id: "90a3f5f9-e022-4416-8240-bb5c556037c2",
        code: "T58",
        translator_error: 961,
        error: "Code word error. This check applies to:\nSubfield 1 of field 35A,35N,35P,35S.\nSubfield 2 of field 35H,35T.\nSubfield 1 in the 2nd occurrence of field 35A in MT550."
    },
    {
        id: "f8e3b46f-bb9f-4c09-ad02-be064a30abb2",
        code: "T59",
        translator_error: 962,
        error: "(Available)."
    },
    {
        id: "4ada083b-0b0e-4b87-9b99-d0d89c0d4954",
        code: "T60",
        translator_error: 963,
        error: "Code word error. This check applies to:\nField 26F in MT306.\nField 40A in MT700,705."
    },
    {
        id: "43ad74d3-e0b9-4630-ab93-764f282602d1",
        code: "T61",
        translator_error: 964,
        error: "Code word 'D' or 'M' error. This check applies to:\nField 32K, subfield 1, in MT400,405,410,416,420,422,430.\nField 33k, subfield 1, in MT430.\nField 37(A-F), subfield 2, in MT516,644,646.\nField 38J, subfield 1, in MT320,330."
    },
    {
        id: "605157c8-bf57-4590-aead-3b9701cff51e",
        code: "T62",
        translator_error: 965,
        error: "Either the first subfield <DATE2>[<HHMM>] or the second subfield 7!a but not both must be present. If optional subfield 1 is used, component 1 <DATE2> of this subfield must be present. This check applies to:\nFields 31H,31J,31X."
    },
    {
        id: "2cf220d0-4f56-40d3-98b8-fd86e352ff76",
        code: "T63",
        translator_error: 966,
        error: "Error in component 2 of field 22C or subfield 2, component 2, of field 22. When the last character of this component is zero '0' and the preceding character is not one '1' then the entire component must consist of zeros."
    },
    {
        id: "2c9f75c2-83fc-42ba-b529-7937713a9b62",
        code: "T64",
        translator_error: 967,
        error: "Code word error. This check applies to:\nField 12F in MT306.\nField 40B, line 1, in MT710,720."
    },
    {
        id: "9cb3bbbf-896f-44b6-9774-cb55f14db04c",
        code: "T65",
        translator_error: 968,
        error: "(Available)."
    },
    {
        id: "67f5c04a-15d9-4ee4-ba47-ca3f4cadf810",
        code: "T66",
        translator_error: 969,
        error: "Code word error. This check applies to:\nField 12E in MT306.\nField 40B, line 2, in MT710,720."
    },
    {
        id: "477a5405-9722-497f-8b49-8cd2947c6c9f",
        code: "T67",
        translator_error: 970,
        error: "Code word error. This check applies to:\nField 17R in MT320,330.\nField 17V in MT306.\nField 49 in MT700,710,720."
    },
    {
        id: "23a3d743-bcbf-4778-9ebb-a8984b6281d1",
        code: "T68",
        translator_error: 971,
        error: "Code word error. This check applies to:\nField 24D, subfield 1, in MT300,306,320,330,240,360,361.\nField 41A, subfield 2, in MT700,705,710,720,740.\nField 41D, subfield 2, in MT700,705,710,720,740."
    },
    {
        id: "bec4f51e-f917-49d3-822c-6dec108e48ad",
        code: "T69",
        translator_error: 972,
        error: "(Available)."
    },
    {
        id: "391aa1ae-2966-4d56-a4b9-80f400e84ffa",
        code: "T70",
        translator_error: 973,
        error: "Either the Account number, the Place, or both must be present."
    },
    {
        id: "4657a882-d2ea-40e5-9d17-1fd09966f60f",
        code: "T71",
        translator_error: 974,
        error: "Code word error. This check applies to:\nField 22D in MT360,361,364,365,405.\nField 22E in MT405.\nField 22G in MT306."
    },
    {
        id: "2dffb7ea-56e8-459b-9397-f4cb2f2e13da",
        code: "T72",
        translator_error: 975,
        error: "Code word error. This check applies to:\nField 22J in MT306.\nField 23C, subfield 1, in MT405.\nField 23F, subfield 1, in MT405."
    },
    {
        id: "fe227699-7ba5-4087-80c5-cebf4ae944d7",
        code: "T73",
        translator_error: 976,
        error: "Invalid country code. Please refer to the BIC Directory General Information -Country Codes-."
    },
    {
        id: "713736aa-c063-405d-8b18-1c65607c9ddd",
        code: "T74",
        translator_error: 977,
        error: "The currency code must be the same for each indicated subfield in the field."
    },
    {
        id: "60e5449c-3b42-4928-b039-5a0be4cc7d2f",
        code: "T75",
        translator_error: 978,
        error: "In MTs 405, n92, n95, n96:\nField 38B .\nWhen subfields 1 and 2 contain \"MONT/OTHR\" then subfield 3 is mandatory, otherwise subfield 3 is not allowed."
    },
    {
        id: "bf583e4b-fa5c-4773-9658-aaa8d95fb2b1",
        code: "T76",
        translator_error: 979,
        error: "The first character in the first line of this field must be a '/', and there must be at least another line, but not more than 5 lines. This check applies to field 50H."
    },
    {
        id: "aa1a6081-b5ce-478e-8be6-218f6a43584b",
        code: "T77",
        translator_error: 980,
        error: "If the first character of the first line of this component or sub-component is a '/', then there must be at least another line, but not more than 5 lines. Otherwise, no more than 4 lines are allowed. This check applies to:\nFields 42D,50K,(50-58)D,59,(82-88)D, and subfield 2 of field 87F."
    },
    {
        id: "a6480014-8864-4e78-9d3b-0f9dd381f7fa",
        code: "T78",
        translator_error: 981,
        error: "Invalid or duplicated code word, or a mandatory code word is missing.\nThis check applies to:\nField 53J, subfield 1 of each line, in MTs 300, 303 and 304, 306, 320, 330, 340, 341, 350.\nField 56J, subfield 1 of each line, in MTs 300, 303 and 304, 306, 320, 330, 340, 341, 350.\nField 57J, subfield 1 of each line, in MTs 300, 303 and 304, 306, 320, 330, 340, 341, 350.\nField 58J, subfield 1 of each line, in MTs 300, 303 and 304, 306, 320, 330, 340, 341, 350.\nField 82J, subfield 1 of each line, in MTs 300, 303 and 304, 305, 306, 320, 330, 350, 600, 601.\nField 83J, subfield 1 of each line, in MTs 300, 303 and 304, 305, 306, 320, 330, 350, 600, 601.\nField 84J, subfield 1 of each line, in MTs 300, 306, 320, 330.\nField 85J, subfield 1 of each line, in MTs 300, 320, 330.\nField 86J, subfield 1 of each line, in MTs 306, 320, 330, 340, 341, 350.\nField 87J, subfield 1 of each line, in MTs 300, 303 and 304, 305, 306, 320, 330, 350, 600, 601.\nField 88J, subfield 1 of each line, in MTs 300, 320."
    },
    {
        id: "bf243f49-1ded-44ff-9176-4c2cb1545788",
        code: "T79",
        translator_error: 982,
        error: "(Available)."
    },
    {
        id: "582c0d15-7c61-4dac-9adf-7f098b37fc29",
        code: "T80",
        translator_error: 983,
        error: "<Field 72 Reject/Return> or <Field 79 Reject Return> code word error, or mandatory code word missing, or code word not in proper sequence."
    },
    {
        id: "4b41a64c-45df-4eba-969b-b2e5afb96ec3",
        code: "T81",
        translator_error: 984,
        error: "Format of <Field 72 Reject/Return> is not allowed in this message. This check applies to:\nField 72, MT102_STP,103_STP."
    },
    {
        id: "9f2a05b1-d5b1-4516-b489-43b7cf55b2e1",
        code: "T82",
        translator_error: 985,
        error: "ERI format is not allowed in this message. This check applies to: Field 72, MT102_STP,103_STP."
    },
    {
        id: "dda1fa23-ff97-4dbf-954d-59d9ee712f03",
        code: "T83",
        translator_error: 986,
        error: "(Available)."
    },
    {
        id: "fd7ec157-e3dd-46f5-b4cb-408131c38079",
        code: "T84",
        translator_error: 987,
        error: "(Available)."
    },
    {
        id: "23ab488d-d0bf-455e-9a74-1a97b2b8cb3e",
        code: "T85",
        translator_error: 988,
        error: "Code word error. This check applies to Field 23G, subfield 2, in MTs 307, 321, 380, 381, 500-510, 513-515, 517-519, 524, 527-529, 530, 535-538, 540-549, 558, 564-569, 574IRSLST, 574W8BENO, 575, 576, 578, 584, 586-589, or in any message appended in common groups n92, n95 or n96."
    },
    {
        id: "b8f9d9e5-8fc8-41b7-8d41-b00214b75fed",
        code: "T86",
        translator_error: 989,
        error: "Code word error. This check applies to:\nField 23G, subfield 1, in MTs 307, 321, 380, 381, 500-510, 513-515, 517-519, 524, 527-529, 530, 535-538, 540-549, 558, 564-569, 574IRSLST, 574W8BENO, 575, 576, 578, 584, 586-589, or in any message appended in common groups n92, n95 or n96."
    },
    {
        id: "8fcfe138-5583-49dc-964b-84795c64a6b9",
        code: "T87",
        translator_error: 990,
        error: "In a generic field the colon ':' delimiter is not present at the expected position."
    },
    {
        id: "4e8d4ec3-c48c-4ce3-a864-cc67bfdec9e8",
        code: "T88",
        translator_error: 991,
        error: "Field 12 in MT570 may only consist of 571,572,573,577.\nField 12 in MT920 may only consist of 940,941,942,950.\nField 12 in MT973 may only consist of 971,972,998.\nField 13A in MT507 may only consist of 503,504,505.\nField 13A in MT549 may only consist of 509,535-538,548,567,575-577,584, 586,589."
    },
    {
        id: "6c882951-2624-4386-8827-5a37025e7e2e",
        code: "T89",
        translator_error: 992,
        error: "In a generic field either the qualifier is invalid, the qualifier is duplicated, a mandatory qualifier is missing, or the qualifier format is not valid."
    },
    {
        id: "2635d5e5-0630-4dec-b789-95a86a61e64c",
        code: "T90",
        translator_error: 993,
        error: "In a generic field either the issuer code format is invalid, the mandatory issuer code is missing, or the generic field format is invalid."
    },
    {
        id: "512f7a41-c91c-45ec-8afb-a15ccd494921",
        code: "T91",
        translator_error: 994,
        error: "In a generic field the slash '/' delimiter is not present at the expected position."
    },
    {
        id: "a045e02f-09b8-433f-a6c7-f012b94b3f7a",
        code: "T92",
        translator_error: 995,
        error: "Code word error. This check applies to:\nField 16R in ISO15022.\nField 16S in ISO15022."
    },
    {
        id: "4fb6b7e4-0398-47c9-aa2f-7b0e71e9471b",
        code: "T93",
        translator_error: 996,
        error: "Code word error. This check applies to:\nField 22B in MT320,330,364,365."
    },
    {
        id: "e2817bb6-b608-4ce9-b1f8-29019511e621",
        code: "T94",
        translator_error: 997,
        error: "In field 22, subfield 2, or in field 22C, the components 1 and 3, the values '0' and '1' are not permitted in <LC1> and <LC2>. However, the value '0' is allowed in the second (right-most) position if the sender or receiver is a test and training user."
    },
    {
        id: "42fc0794-708c-4958-9240-f6bf0344332e",
        code: "T95",
        translator_error: 998,
        error: "In field 22, subfield 2, or in field 22C, the components 1 and 3 do not contain the bank code and location code of the message sender, and/or the bank code and location code of the message receiver."
    },
    {
        id: "86761fcd-26b4-42d4-99b5-49229bbe8683",
        code: "T96",
        translator_error: 999,
        error: "In field 22, subfield 2, or in field 22C, the components 1 and 3 are not in alphabetical sequence."
    },
    {
        id: "43414860-a889-43b0-a923-243ef02cdc29",
        code: "T97",
        translator_error: 1000,
        error: "Code word error. This check applies to:\nField 28E, subfield 2, in MT206,506,535-538,569,574WBENO,574IRSLST,575, 576,584,586."
    },
    {
        id: "4137061a-23fd-40da-819b-53b599cb14ce",
        code: "T98",
        translator_error: 1001,
        error: "(Available)."
    },
    {
        id: "4a7af50c-e6e6-4e67-b1c2-307eba191252",
        code: "T99",
        translator_error: 1002,
        error: "A special function has been declared in the validation syntax that is not recognized."
    }
];
