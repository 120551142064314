import { Component, Input, OnInit } from '@angular/core';
import { ServiceRequest } from 'app/modules/admin/apps/service-request/service-request.types';
import { TaskStatus } from 'app/modules/admin/apps/tasks/tasks.types';

@Component({
  selector: 'app-item-tasks',
  templateUrl: './item-tasks.component.html',
  styleUrls: ['./item-tasks.component.scss']
})
export class ItemTasksComponent implements OnInit {
  @Input() mission: any;
  @Input() service: any;
  @Input() sr: any;
  @Input() delegate: any;
  @Input() serviceRequests: ServiceRequest;
  @Input() serviceRequestsLength: number;
  taskStatus = TaskStatus
  constructor() { }
  ngOnInit(): void { }
}