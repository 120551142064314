import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription, BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ApiResponse } from 'app/shared/models/response.model'; // Update path as necessary
import { environment } from 'assets/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountApiService implements OnDestroy {
  private baseUrl = environment.apiUrl;
  private pollingSubscription?: Subscription;

  // BehaviorSubject to hold and broadcast the count
  private countSubject = new BehaviorSubject<number>(0);
  public count$ = this.countSubject.asObservable();

  constructor(private http: HttpClient) {
    this.startPolling();
  }

  private getToken(): string | null {
    // Replace this with your actual token retrieval logic
    return localStorage.getItem('accessToken');
  }

  startPolling(): void {
    const token = this.getToken();

    if (token) {
      this.pollingSubscription = interval(5000)
        .pipe(switchMap(() => this.fetchCount()))
        .subscribe(
          (count) => this.countSubject.next(count),
          (error) => console.error('Error fetching count:', error),
        );
    } else {
      console.warn('Token not found. Polling will not start.');
    }
  }

  fetchCount() {
    const status = 'SUBMITTED';
    return this.http
      .get<ApiResponse<number>>(`${this.baseUrl}/ms/api/v1/travel-services/service-request/count`, {
        params: { status },
      })
      .pipe(map((response) => response.count));
  }

  ngOnDestroy(): void {
    this.pollingSubscription?.unsubscribe();
  }
}
