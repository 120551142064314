import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { AppGuard } from './core/auth/guards/app.guard';
import { FunctionalRoles } from './core/user/user.types';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboards/project'
  { path: '', pathMatch: 'full', redirectTo: 'dashboards' },

  // Redirect signed-in user to the '/dashboards/project'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'dashboards/project',
  },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'implicit/callback',
        component: CallbackComponent,
        data: { title: 'Callback', titleI18n: 'callback' },
      },
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then(
            (m) => m.AuthConfirmationRequiredModule
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(
            (m) => m.AuthForgotPasswordModule
          ),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },

      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },

      {
        path: 'travel-agent-sign-in',
        loadChildren: () =>
          import('app/modules/auth/travel-agent-sign-in/travel-agent-sign-in.module').then(
            (m) => m.TravelAgentSignInModule
          ),
      },

      {
        path: 'travel-agent-sign-up',
        loadChildren: () =>
          import('app/modules/auth/travel-agent-sign-up/travel-agent-sign-up.module').then(
            (m) => m.TravelAgentSignUpModule
          ),
      },

      {
        path: 'email-verification',
        loadChildren: () =>
          import('app/modules/auth/email-verification/email-verification.module').then(
            (m) => m.EmailVerificationModule
          ),
      },

      {
        path: 'travel-agent/email-verification',
        loadChildren: () =>
          import(
            'app/modules/auth/travel-agent-verification/travel-agent-verification.module'
          ).then((m) => m.TravelAgentVerificationModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    // canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      {
        path: 'dashboards',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('app/modules/admin/dashboards/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
              ),
          },
          {
            path: 'project',
            loadChildren: () =>
              import('app/modules/admin/dashboards/project/project.module').then(
                (m) => m.ProjectModule
              ),
          },
          {
            path: 'analytics',
            loadChildren: () =>
              import('app/modules/admin/dashboards/analytics/analytics.module').then(
                (m) => m.AnalyticsModule
              ),
          },
          {
            path: 'finance',
            loadChildren: () =>
              import('app/modules/admin/dashboards/finance/finance.module').then(
                (m) => m.FinanceModule
              ),
          },
          {
            path: 'crypto',
            loadChildren: () =>
              import('app/modules/admin/dashboards/crypto/crypto.module').then(
                (m) => m.CryptoModule
              ),
          },
        ],
      },

      // Apps
      {
        path: 'apps',
        children: [
          {
            path: 'mission',
            data: { roles: [FunctionalRoles.TRV_OFFICER] },
            loadChildren: () =>
              import('app/modules/admin/apps/mission/mission.module').then((m) => m.MissionModule),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'service-request',
            data: { roles: [FunctionalRoles.TRV_OFFICER] },
            loadChildren: () =>
              import('app/modules/admin/apps/service-request/service-request.module').then(
                (m) => m.ServiceRequestModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'contacts',
            data: { roles: [FunctionalRoles.TRV_ADMIN] },
            loadChildren: () =>
              import('app/modules/admin/apps/contacts/contacts.module').then(
                (m) => m.ContactsModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'tasks',
            data: { roles: [FunctionalRoles.TRV_OFFICER] },
            loadChildren: () =>
              import('app/modules/admin/apps/tasks/tasks.module').then((m) => m.TasksModule),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'completed-tasks',
            data: { roles: [FunctionalRoles.TRV_OFFICER] },
            loadChildren: () =>
              import('app/modules/admin/apps/completed-tasks/completed-tasks.module').then(
                (m) => m.CompletedTasksModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'approval-requests',
            data: { roles: [FunctionalRoles.TRV_SUPERVISOR] },
            loadChildren: () =>
              import('app/modules/admin/apps/approval-requests/approval-requests.module').then(
                (m) => m.ApprovalRequestsModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'settings',
            data: { roles: [FunctionalRoles.TRV_ADMIN] },
            loadChildren: () =>
              import('app/modules/admin/apps/settings/settings.module').then(
                (m) => m.SettingsModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'system-administration',
            loadChildren: () =>
              import('app/modules/admin/apps/system-administration/settings.module').then(
                (m) => m.SettingsModule
              ),
          },
          {
            path: 'missions',
            data: { roles: [FunctionalRoles.TRV_STAFF] },
            loadChildren: () =>
              import('app/modules/admin/apps/my-missions/my-missions.module').then(
                (m) => m.MyMissionsModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'requests',
            // data: { roles: [FunctionalRoles.TRV_STAFF] },
            loadChildren: () =>
              import('app/modules/admin/apps/user-requests/user-requests.module').then(
                (m) => m.UserRequestsModule
              ),
            // canActivate: [AppGuard],
            // canActivateChild: [AppGuard],
          },
          {
            path: 'request',
            loadChildren: () =>
              import('app/modules/admin/apps/request/request.module').then((m) => m.RequestModule),
          },
          {
            path: 'initiated-requests',
            data: { roles: [FunctionalRoles.TRV_OFFICER] },
            loadChildren: () =>
              import('app/modules/admin/apps/officer-activities/officer-activities.module').then(
                (m) => m.OfficerActivitiesModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'bid-management',
            data: { roles: [FunctionalRoles.TRV_OFFICER] },
            loadChildren: () =>
              import('app/modules/admin/apps/bid-management/bid-management.module').then(
                (m) => m.BidManagementModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'travel-agent',
            data: { roles: [FunctionalRoles.TRV_AGENT] },
            loadChildren: () =>
              import('app/modules/admin/apps/travel-agent/travel-agent.module').then(
                (m) => m.TravelAgentModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'travel-agents',
            data: { roles: [FunctionalRoles.TRV_AGENT] },
            loadChildren: () =>
              import('app/modules/admin/apps/all-travel-agents/all-travel-agents.module').then(
                (m) => m.AllTravelAgentsModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
          {
            path: 'travel-agent-task',
            data: { roles: [FunctionalRoles.TRV_AGENT] },
            loadChildren: () =>
              import('app/modules/admin/apps/travel-agent-task/travel-agent-task.module').then(
                (m) => m.TravelAgentTaskModule
              ),
            // canActivate: [AppGuard],
            canActivateChild: [AppGuard],
          },
        ],
      },

      // Pages
      {
        path: 'pages',
        children: [
          // Authentication
          {
            path: 'authentication',
            loadChildren: () =>
              import('app/modules/admin/pages/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
              ),
          },

          // Error
          {
            path: 'error',
            children: [
              {
                path: '404',
                loadChildren: () =>
                  import('app/modules/admin/pages/error/error-404/error-404.module').then(
                    (m) => m.Error404Module
                  ),
              },
              {
                path: '500',
                loadChildren: () =>
                  import('app/modules/admin/pages/error/error-500/error-500.module').then(
                    (m) => m.Error500Module
                  ),
              },
            ],
          },

          // Invoice
          {
            path: 'invoice',
            children: [
              {
                path: 'printable',
                children: [
                  {
                    path: 'compact',
                    loadChildren: () =>
                      import(
                        'app/modules/admin/pages/invoice/printable/compact/compact.module'
                      ).then((m) => m.CompactModule),
                  },
                  {
                    path: 'modern',
                    loadChildren: () =>
                      import('app/modules/admin/pages/invoice/printable/modern/modern.module').then(
                        (m) => m.ModernModule
                      ),
                  },
                ],
              },
            ],
          },
          // Profile
          {
            path: 'profile',
            loadChildren: () =>
              import('app/modules/admin/pages/profile/profile.module').then((m) => m.ProfileModule),
          },

          // Settings
        ],
      },

      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
          import('app/modules/admin/pages/error/error-404/error-404.module').then(
            (m) => m.Error404Module
          ),
      },
      { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
