import { Component, Input, OnInit } from '@angular/core';
import { ServiceRequest } from 'app/modules/admin/apps/service-request/service-request.types';
@Component({
    selector: 'app-mission-details-item',
    templateUrl: './mission-detail.component.html',
    styleUrls: ['./mission-detail.component.scss'],
})
export class MissionDetailComponent implements OnInit {
    @Input() mission: any;
    @Input() service: any;
    @Input() sr: any;
    @Input() delegate: any;
    @Input() serviceRequests: ServiceRequest;
    @Input() serviceRequestsLength: number;
    constructor() {}
    ngOnInit(): void {}
}
