export interface User {
  id: string;
  name: string;
  email: string;
  functional_roles: string[];
  avatar?: string;
  status?: string;
}

export enum FunctionalRoles {
  TRV_STAFF = 'STAFF',
  TRV_OFFICER = 'TRV_OFFICER',
  TRV_AGENT = 'TRV_AGENT',
  TRV_ADMIN = 'TRV_ADMIN',
  TRV_SUPERVISOR = 'TRV_SUPERVISOR',
  TRV_LEVEL_1 = 'TRV_LEVEL_1',
  TRV_LEVEL_2 = 'TRV_LEVEL_2',
  TRV_LEVEL_3 = 'TRV_LEVEL_3',
  TRV_LEVEL_4 = 'TRV_LEVEL_4',
}
