import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, catchError, EMPTY } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserService } from 'app/core/user/user.service';
import { OktaResponse } from 'app/shared/models/okta.model';
import { StaffLocationEnum } from 'app/shared/enums/staff.enum';
import { staffTypes } from 'app/shared/constants/staff.constant';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-staff-sign-up',
  templateUrl: './staff-sign-up.component.html',
  styleUrls: ['./staff-sign-up.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    NgSelectModule,
    MatProgressSpinnerModule,
  ],
})
export class StaffSignUpComponent implements OnInit {
  isLoading$ = new BehaviorSubject(false);
  staffForm!: FormGroup;
  staffLocations = Object.values(StaffLocationEnum);
  staffTypes = staffTypes;
  oktaData;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<StaffSignUpComponent>
  ) {
    this.oktaData = data;
  }

  ngOnInit(): void {
    this.buildStaffForm();

    if (this.data) {
      this.patchForm(this.data);
    }
  }

  get f() {
    return this.staffForm.controls;
  }

  get emailArray() {
    return this.staffForm.get('emails') as FormArray;
  }

  get phoneArray() {
    return this.staffForm.get('phoneNumbers') as FormArray;
  }

  buildStaffForm() {
    this.staffForm = this.fb.group({
      firstName: [{ value: null, disabled: true }],
      lastName: [{ value: null, disabled: true }],
      type: ['INTERN', Validators.required],
      title: ['', Validators.required],
      location: ['Cairo', Validators.required],
      department: [''],
      employeeId: [''],
      grade: [''],
      emails: this.fb.array([this.createEmailGroup('Home'), this.createEmailGroup('Office')]),
      phoneNumbers: this.fb.array([this.createPhoneGroup('Home'), this.createPhoneGroup('Office')]),
    });
  }

  createEmailGroup(label: 'Home' | 'Office') {
    return this.fb.group({
      email: [''],
      label: [label],
    });
  }

  createPhoneGroup(label: 'Home' | 'Office') {
    return this.fb.group({
      country: [''],
      phoneNumber: [''],
      label: [label],
    });
  }

  patchForm(data: OktaResponse) {
    this.staffForm.patchValue({
      firstName: data.user?.firstname,
      lastName: data.user?.lastname,
      emails: [
        { label: 'Home', email: data.user?.email },
        { label: 'Office', email: data.user?.email },
      ],
    });

    this.staffForm.get('emails').disable();
  }

  close() {
    this.dialogRef.close(this.data);
  }

  submit() {
    this.isLoading$.next(true);
    const payload = this.staffForm.value;

    this.userService
      .createStaff(payload)
      .pipe(catchError(() => EMPTY))
      .subscribe((response) => {
        console.log(response, 'staff created successfully');

        this.close();
        this.isLoading$.next(false);
      });
  }
}
