import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { environment } from 'assets/environments/environment';
import { Staff } from 'app/modules/admin/apps/service-request/service-request.types';
import { StaffDTO } from 'app/shared/models/staff';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.apiUrl;
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    // return this._user.asObservable();
    return this.get();
  }

  /**
   * Setter & getter for user details
   */
  set userDetails(token: string) {
    localStorage.setItem('user', token);
  }

  get userDetails(): string {
    return localStorage.getItem('user') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    const user = this.userDetails;
    return new Observable((subscriber) => {
      subscriber.next(JSON.parse(user));
    });
    /*return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                console.log('User', user);
                this._user.next(user);
            })
        );*/
  }

  createStaff(payload: StaffDTO) {
    const url = `${this.baseUrl}/ms/api/v1/travel-services/staff`;

    return this._httpClient.post(url, payload);
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        console.log('User update response', response);
        this._user.next(response);
      })
    );
  }

  getUserByEmail(email: string): Observable<Staff> {
    return this._httpClient.get<Staff>(
      `${this.baseUrl}/ms/api/v1/travel-services/staff/email?email=${email}`
    );
  }
}
