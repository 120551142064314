import { Component, Input } from '@angular/core';
import { MissionService } from 'app/modules/admin/apps/mission/mission.service';
import { Mission } from 'app/modules/admin/apps/mission/mission.types';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mission-item',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
})
export class MissionComponent {
  @Input() mission: any;

  missions$: Observable<Mission[]>;

  constructor() {}
}
