import { ServiceRequest } from '../service-request/service-request.types';

export interface Tag {
  id?: string;
  title?: string;
}

export interface Task {
  bid: any;
  tags: string[];
  assignee: Assignee[];
  cancelledAt: string;
  closedAt: string;
  code: string;
  createdAt: string;
  deletedAt: string;
  completedAt: string;
  description: string;
  documents: any[];
  comments: Comment[];
  dueDate: string;
  events: any[];
  id: string;
  name: string;
  notes: string;
  priority: any;
  reference: string;
  status: string;
  updatedAt: string;
  completed: boolean;
  isOnHold: boolean;
  canEdit: boolean;
  canResume: boolean;
  canRecall: boolean;
  canComplete: boolean;
  canPutOnHold: boolean;
  completedBy: any;
  reasonForOnHold: string;
  serviceRequest: ServiceRequest;
  type: any;
  taskRequests: any;
  taskDetails?: {
    airportAssistance: boolean;
    date: string;
    destination: string;
    detail: string;
    extraLuggage: boolean;
    flightClass: string;
    origin: string;
    transportFrom?: string;
    transportTo?: string;
    pickUpDate?: string;
    dropOffDate?: string;
    checkInDate: string;
    checkOutDate: string;
    city: string;
    country: string;
    type: string;
  }[];
}

export interface Comment {
  closedAt: string;
  createdAt: string;
  deletedAt: string;
  documents: any[];
  id: string;
  message: string;
  status: string;
  updatedAt: string;
  user: User;
}

export interface User {
  closedAt: string;
  createdAt: string;
  deletedAt: string;
  email: string;
  id: string;
  name: string;
  profile: any;
  updatedAt: string;
  userId: string;
}

export interface Assignee {
  closedAt: string;
  createdAt: string;
  deletedAt: string;
  email: string;
  id: string;
  name: string;
  profile: any;
  updatedAt: string;
  userId: string;
}

export enum TaskStatus {
  COMPLETED = 'completed',
  COMPLETE = 'complete',
  ARCHIVED = 'archived',
  APPROVED = 'approved',
  SUBMITTED = 'submitted',
  DECLINED = 'declined',
  NEW = 'NEW',
  ON_HOLD = 'on-hold',
  ASSIGNED = 'assigned',
  DELETED = 'deleted',
  TRANSFERRED = 'transferred',
  ROUTED = 'to next level',
}

export const TaskStatuses = [
  {
    name: 'completed',
    id: TaskStatus.COMPLETED,
  },
  {
    name: 'archived',
    id: TaskStatus.ARCHIVED,
  },
  {
    name: 'new',
    id: TaskStatus.NEW,
  },
  {
    name: 'on-hold',
    id: TaskStatus.ON_HOLD,
  },
  {
    name: 'assigned',
    id: TaskStatus.ASSIGNED,
  },
  {
    name: 'deleted',
    id: TaskStatus.DELETED,
  },

  {
    name: 'transferred',
    id: TaskStatus.TRANSFERRED,
  },

  {
    name: 'to next level',
    id: TaskStatus.ROUTED,
  },
];

export enum TaskAction {
  COMPLETE = 'complete',
  SET_PRIORITY = 'set-priority',
  ARCHIVE = 'archive',
  SELF_ASSIGN = 'self-assign',
  REROUTE = 'reroute',
  ROUTE = 'route',
  ON_HOLD = 'on-hold',
  RESUME = 'resume',
  RECALL = 'recall',
  LINK_TO_RELATED_TASK = 'link-to-related-task',
  UNLINK_RELATED_TASK = 'unlink-related-task',
}

export enum TaskPriority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum TaskAction {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}
