import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '../confirmation.types';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'fuse-confirmation-dialog',
  templateUrl: './dialog.component.html',
})
export class FuseConfirmationDialogComponent implements OnInit {
  @ViewChild('close') close!: ElementRef;

  public reasonForm!: UntypedFormGroup;

  @Output() event: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() messageEvent: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initReasonForm();
  }

  public initReasonForm(): void {
    this.reasonForm = this._formBuilder.group({
      message: ['', Validators.required],
    });
  }

  public closeModal(): void {
    this.event.emit(false);
    this.close.nativeElement.click();
  }

  public submit(): void {
    if (this.reasonForm.invalid) {
      this.reasonForm.markAllAsTouched();
      return;
    }

    this.messageEvent.emit(this.reasonForm.value.message);
  }
}
