<div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
  <!-- Logo -->
  <div class="w-12">
    <img src="assets/images/logo/logo.svg" />
  </div>

  <!-- Title -->
  <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">
    Sign in
  </div>

  <!-- Alert -->
  <fuse-alert
    class="mt-8"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="'info'"
  >
    You are <strong>Travel Agent</strong>. Click the button to verify your email.
  </fuse-alert>

  <!-- Alert -->
  <fuse-alert
    class="mt-8"
    *ngIf="showAlert"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>

  <!-- Sign in form -->
  <form class="mt-8" [formGroup]="signInForm" #signInNgForm="ngForm">
    <!-- Email field -->
    <mat-form-field class="w-full">
      <mat-label>Email address</mat-label>
      <input id="email" matInput [formControlName]="'email'" />
      <mat-error *ngIf="signInForm.get('email').hasError('required')">
        The Email address is required
      </mat-error>
      <mat-error *ngIf="signInForm.get('email').hasError('email')">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>

    <!-- Alert -->
    <fuse-alert
      class="mt-8"
      [appearance]="'outline'"
      [showIcon]="false"
      [type]="'info'"
      *ngIf="isVerified"
    >
      Please type <strong>code/password</strong> received by email.
    </fuse-alert>

    <mat-form-field class="w-full mt-8" *ngIf="isVerified">
      <mat-label>Enter the code</mat-label>
      <input id="code" matInput [formControlName]="'code'" />
      <mat-error *ngIf="signInForm.get('code').hasError('required')">
        The Code is required
      </mat-error>
    </mat-form-field>

    <!-- Submit button -->
    <button
      class="fuse-mat-button-large w-full mt-6"
      mat-flat-button
      [color]="'primary'"
      [disabled]="signInForm.disabled"
      (click)="checkEmail()"
      *ngIf="!isVerified"
    >
      <span *ngIf="!signInForm.disabled"> Verify Email </span>
      <mat-progress-spinner
        *ngIf="signInForm.disabled"
        [diameter]="24"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>
    </button>

    <!-- Submit button -->
    <button
      class="fuse-mat-button-large w-full mt-6"
      mat-flat-button
      [color]="'primary'"
      [disabled]="signInForm.disabled"
      (click)="emailSignIn()"
      *ngIf="isVerified"
    >
      <span *ngIf="!signInForm.disabled"> Sign in </span>
      <mat-progress-spinner
        *ngIf="signInForm.disabled"
        [diameter]="24"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>
    </button>
  </form>
</div>
