import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'assets/environments/environment';
import { Agent } from './auth.types';
import { SSOAuthService } from '../../shared/services/sso-auth.service';
import { Router } from '@angular/router';
import { OktaAppService } from 'app/shared/services/okta-app.service';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private _agents$: BehaviorSubject<Agent[] | null> = new BehaviorSubject(null);
  private _agent: BehaviorSubject<Agent | null> = new BehaviorSubject(null);
  private baseUrl = environment.apiUrl;

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    private _userService: UserService,
    private authService: SSOAuthService,
    private oktaService: OktaAppService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // set role(role: string) {
  //   localStorage.setItem('role', role);
  // }

  /**
   * Getter for tasks
   */
  get agents$(): Observable<any[]> {
    return this._agents$.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post('api/auth/sign-in', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;
        // this.role = response.user.functional_roles;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExp');
    localStorage.removeItem('user');

    localStorage.clear();
    this.oktaService.logout();
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  /**
   * Get Travel Agents
   *
   *
   */
  getTravelAgents(): void {
    const url = `${environment.apiUrl}/ms/api/v1/auth/member/travel/agent`;

    this._httpClient.get<Agent[]>(url).subscribe((response) => {
      this._agents$.next(response);
    });
  }

  checkEmail(email: string) {
    try {
      const redirectURL = localStorage.getItem('lastRoute') || '/dashboards/projects';
      return this.authService.checkEmail(email);
      /*return this.authService
                .oauth(oktaData)
                .pipe(catchError(async (err) => this.toastr.error(err)))
                .subscribe(
                    (data?: { token: string; exp: number; iat: number; user: any }) => {
                      console.log('data', data);
                      if (data && data.token) {
                        // console.log(data);
                        this.accessToken = data.token;
                        this.accessTokenExpiry = data.exp.toString();

                        this._authenticated = true;
                        const _user = {} as User;
                        _user.id = data.user._id;
                        _user.email = data.user.email;
                        _user.functional_roles = data.user.functional_roles;
                        _user.name = `${data.user.firstname} ${data.user.lastname}`;
                        _user.status = 'online';
                        this.userDetails = JSON.stringify(_user);
                        this._userService.user = _user;
                        this.router.navigate([redirectURL]);
                      }
                    }
                );*/
    } catch (err) {
      console.log('err', err);
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
    }
  }

  emailSignIn(hash: string, code: string) {
    try {
      const redirectURL = localStorage.getItem('lastRoute') || '/dashboards/projects';
      return this.authService.emailSignIn(hash, code);
      /*return this.authService
                .oauth(oktaData)
                .pipe(catchError(async (err) => this.toastr.error(err)))
                .subscribe(
                    (data?: { token: string; exp: number; iat: number; user: any }) => {
                      console.log('data', data);
                      if (data && data.token) {
                        // console.log(data);
                        this.accessToken = data.token;
                        this.accessTokenExpiry = data.exp.toString();

                        this._authenticated = true;
                        const _user = {} as User;
                        _user.id = data.user._id;
                        _user.email = data.user.email;
                        _user.functional_roles = data.user.functional_roles;
                        _user.name = `${data.user.firstname} ${data.user.lastname}`;
                        _user.status = 'online';
                        this.userDetails = JSON.stringify(_user);
                        this._userService.user = _user;
                        this.router.navigate([redirectURL]);
                      }
                    }
                );*/
    } catch (err) {
      console.log('err', err);
      localStorage.removeItem('currentUser');
      this._router.navigate(['/login']);
    }
  }

  loginAsTravelOfficer(user: {
    email: string;
    lastname: string;
    firstname: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post(`${this.baseUrl}/ms/api/v1/auth/sign-up`, user).pipe(
      switchMap((response: any) => {
        localStorage.setItem('user', JSON.stringify(response.user));
        // Store the access token in the local storage
        this.accessToken = response.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  // travel agent auth

  verifyEmail(payload: { email: string }): Observable<any> {
    return this._httpClient.post(
      `${this.baseUrl}/ms/api/v1/auth/travel-agent/verify-email`,
      payload,
      {
        observe: 'response',
      }
    );
  }

  authorizeAgent(bearerToken: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${bearerToken}`,
    });

    return this._httpClient.get(`${this.baseUrl}/ms/api/v1/auth/travel-agent/authorize`, {
      headers: headers,
    });
  }

  signUpAsTravelAgent(
    user: {
      firstname: string;
      lastname: string;
      company: string;
      department: string;
    },
    id: string
  ): Observable<any> {
    return this._httpClient
      .post(`${this.baseUrl}/ms/api/v1/auth/travel-agent/${id}`, user)
      .pipe
      // switchMap((response: any) => {
      //   localStorage.setItem('user', JSON.stringify(response.user));
      //   // Store the access token in the local storage
      //   this.accessToken = response.token;
      //   console.log(this.accessToken);

      //   // Set the authenticated flag to true
      //   this._authenticated = true;

      //   // Store the user on the user service
      //   this._userService.user = response.user;

      //   // Return a new observable with the response
      //   return of(response);
      // })
      ();
  }
}
