import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { nackCodes as nackData } from 'app/mock-api/apps/nack/data';

@Injectable({
    providedIn: 'root'
})
export class NacksMockApi
{
    private _nackCodes: any[] = nackData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Contacts - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/nacks/all')
            .reply(() => {

                // Clone the contacts
                const nacks = cloneDeep(this._nackCodes);

                // Sort the contacts by the name field by default
                nacks.sort((a, b) => a.code.localeCompare(b.code));

                // Return the response
                return [200, nacks];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contacts Search - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/nacks/search')
            .reply(({request}) => {

                // Get the search query
                const query = request.params.get('query');

                // Clone the contacts
                let nacks = cloneDeep(this._nackCodes);

                // If the query exists...
                if ( query )
                {
                    // Filter the contacts
                    nacks = nacks.filter(nack => nack.name && nack.name.toLowerCase().includes(query.toLowerCase()));
                }

                // Sort the contacts by the name field by default
                nacks.sort((a, b) => a.code.localeCompare(b.code));

                // Return the response
                return [200, nacks];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Contact - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/nacks/nack')
            .reply(({request}) => {

                // Get the id from the params
                const id = request.params.get('id');

                // Clone the contacts
                const nacks = cloneDeep(this._nackCodes);

                // Find the contact
                const nack = nacks.find(item => item.id === id);

                // Return the response
                return [200, nack];
            });
        // -----------------------------------------------------------------------------------------------------
        // @ Avatar - POST
        // -----------------------------------------------------------------------------------------------------

        /**
         * Read the given file as mock-api url
         *
         * @param file
         */
        const readAsDataURL = (file: File): Promise<any> =>

            // Return a new promise
            new Promise((resolve, reject) => {

                // Create a new reader
                const reader = new FileReader();

                // Resolve the promise on success
                reader.onload = (): void => {
                    resolve(reader.result);
                };

                // Reject the promise on error
                reader.onerror = (e): void => {
                    reject(e);
                };

                // Read the file as the
                reader.readAsDataURL(file);
            });
    }
}
