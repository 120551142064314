<div class="h-full pb-4 overflow-y-auto">
  <form [formGroup]="staffForm">
    <div class="space-y-2 mb-8">
      <h1 class="font-medium text-xl md:text-4xl">Create Account</h1>
      <p class="text-base">Fill in the details to continue.</p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 items-start gap-5">
      <div class="w-full">
        <label class="font-medium text-sm">First name</label>

        <input class="form-control" type="text" formControlName="firstName" />
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Last name</label>

        <input class="form-control" type="text" formControlName="lastName" />
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Type</label>

        <ng-select
          class="mt-1"
          [items]="staffTypes"
          bindLabel="label"
          formControlName="type"
          [searchable]="false"
          [clearable]="true"
        >
        </ng-select>
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Title</label>

        <input class="form-control" type="text" formControlName="title" />
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Location</label>

        <ng-select
          class="mt-1"
          [items]="staffLocations"
          bindLabel="label"
          formControlName="location"
          [searchable]="false"
          [clearable]="true"
        >
        </ng-select>
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Department</label>

        <input class="form-control" type="text" formControlName="department" />
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Employee</label>

        <input class="form-control" type="text" formControlName="employeeId" />
      </div>

      <div class="w-full">
        <label class="font-medium text-sm">Grade</label>

        <input class="form-control" type="text" formControlName="grade" />
      </div>

      <ng-container formArrayName="emails">
        <div
          class="w-full"
          *ngFor="let control of emailArray.controls; let i = index"
          [formGroupName]="i"
        >
          <label class="font-medium text-sm">{{ control.get('label')?.value }} email</label>

          <input class="form-control" type="email" formControlName="email" />
        </div>
      </ng-container>

      <ng-container formArrayName="phoneNumbers">
        <ng-container
          *ngFor="let control of phoneArray.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="w-full">
            <label class="font-medium text-sm">{{ control.get('label')?.value }} country </label>

            <input class="form-control" formControlName="country" />
          </div>

          <div class="w-full">
            <label class="font-medium text-sm">
              {{ control.get('label')?.value }} phone number
            </label>

            <input class="form-control" formControlName="phoneNumber" />
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="flex justify-end gap-4 items-center mt-8">
      <button
        mat-flat-button
        class="submit-btn"
        type="submit"
        (click)="submit()"
        [disabled]="staffForm.invalid"
      >
        Initiate Request

        <mat-spinner *ngIf="isLoading$ | async" diameter="20" class="spinner-white"></mat-spinner>
      </button>
    </div>
  </form>
</div>
