<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  <div class="flex justify-between items-center">
    <h1 class="font-bold fuse-alert-title text-xl text-titleCardColor">
      Confirm Action
    </h1>

    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x'"
      ></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <mat-dialog-content class="mat-typography">
    <!-- <div class="d-flex justify-content-center mb-4">
      <img src="assets/icons/confirm.svg" alt="img" />
    </div> -->
    <!-- <p class="text-center mb-4">
      Are you sure you want to perform this action?
    </p> -->

    <form [formGroup]="reasonForm">
      <!-- <app-error [error_message]="error_message"></app-error> -->

      <!-- Notes -->
      <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
        <mat-label>Comment</mat-label>

        <textarea
          matInput
          [formControlName]="'message'"
          class="form-control"
          [spellcheck]="false"
          cdkTextareaAutosize
          [ngClass]="{
            'is-invalid':
              (reasonForm.get('message')?.errors &&
                reasonForm.get('message')?.touched) ||
              reasonForm.get('message')?.hasError('required')
          }"
        ></textarea>

        <mat-error *ngIf="reasonForm.get('message')?.errors">
          <img src="/assets/icons/error.svg" alt="" class="w-4" />
          Required </mat-error
        >˝</mat-form-field
      >
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <!-- Cancel -->
    <button mat-stroked-button #close mat-button mat-dialog-close class="mr-2">
      No, Cancel
    </button>

    <!-- Confirm -->
    <button mat-flat-button [color]="'primary'" (click)="submit()">
      Continue
    </button>
  </mat-dialog-actions>
</div>
