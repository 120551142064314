import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissionComponent } from './components/mission/mission.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ServiceRequestItemComponent } from './components/service-request/service-request.component';
import { MissionDetailComponent } from './components/mission-detail/mission-detail.component';
import { MatListModule } from '@angular/material/list';
import { ItemTasksComponent } from './components/item-tasks/item-tasks.component';
import { AveragePipeModule } from '@fuse/pipes/average/average.module';
import { SignInDialogComponent } from './dialog/sign-in-dialog/sign-in-dialog.component';
import { QuillEditorComponent } from 'ngx-quill';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FuseAlertModule } from '../../@fuse/components/alert';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AvatarModule } from '@fuse/components/avatar/avatar.module';
import { TextTruncatePipeModule } from '@fuse/pipes/text-truncate/text-truncate.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    RouterLink,
    MatListModule,
    MatProgressBarModule,
    AveragePipeModule,
    QuillEditorComponent,
    MatInputModule,
    MatButtonModule,
    FuseAlertModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    AvatarModule,
    TextTruncatePipeModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MissionComponent,
    ServiceRequestItemComponent,
    MissionDetailComponent,
    ItemTasksComponent,
  ],
  declarations: [
    MissionComponent,
    MissionDetailComponent,
    ServiceRequestItemComponent,
    ItemTasksComponent,
    SignInDialogComponent,
  ],
})
export class SharedModule {}
