import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, tap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthService } from 'app/core/auth/auth.service';

export const WITHOUT_BEARER = new HttpContextToken(() => false);
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private toastr: ToastrService) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // newReq = req.clone({
    //   headers: req.headers.set('x-access-token', 'secret'),
    // });
    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
      let headers = new HttpHeaders({
        // 'x-access-token': 'secret',
        Authorization: `Bearer ${this._authService.accessToken}`,
      });

      if (req.context.get(WITHOUT_BEARER) === false) {
        newReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken),
          // .set('x-access-token', 'secret'),
        });
      } else {
        newReq = req.clone();
      }
    }

    const ifConnected = window.navigator.onLine;

    // Response
    if (ifConnected) {
      return next.handle(newReq).pipe(
        tap({
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (newReq.method != 'GET' && event['body']) {
                // this.toastr.success('Successful');
              }
            }
          },
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.handleError(err, newReq, next);
            }
          },
        })
        // catchError((error) => {
        //   if (newReq.method == 'GET') {
        //     this.toastr.error(error?.error?.message);
        //   } else {
        //     // Catch "401 Unauthorized" responses
        //     if (error instanceof HttpErrorResponse && error.status === 401) {
        //       // Sign out
        //       this._authService.signOut();

        //       // Reload the app
        //       location.reload();
        //     }

        //     return throwError(error);
        //   }
        // })
      );
    } else {
      this.toastr.error('Internet not connected');
      return throwError(() => ({ message: 'Internet not connected' }));
    }
  }

  private handleError(error: HttpErrorResponse, newRequest?: HttpRequest<any>, next?: HttpHandler) {
    if (error.error instanceof ErrorEvent && error.error != null) {
      this.toastr.error(error.error?.message);
    } else {
      if (error.status === 200 && error.statusText === 'OK') {
        console.error(error);
      } else {
        this.toastr.error(error.error?.message || error.statusText);
      }

      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Sign out
        this._authService.signOut();

        // Reload the app
        // location.reload();
      }

      // console.error(
      //   `Backend returned code ${error.status}, ` + `body was: ${error.error?.message}`
      // );
    }

    return of(error);
  }
}
