import { Layout } from 'app/layout/layout.types';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;
export type Themes = { id: string; name: string }[];

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  scheme: Scheme;
  screens: Screens;
  theme: Theme;
  themes: Themes;
  routes: any;
  endpoints: any;
  oidc: any;
  frontendUrl: string;
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 *
 * "Screens" are carried over to the BreakpointObserver for accessing them within
 * components, and they are required.
 *
 * "Themes" are required for Tailwind to generate themes.
 */
export const appConfig: AppConfig = {
  layout: 'classy',
  scheme: 'light',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
  },
  theme: 'theme-brand',
  themes: [
    {
      id: 'theme-default',
      name: 'Default',
    },
    {
      id: 'theme-brand',
      name: 'Brand',
    },
    {
      id: 'theme-teal',
      name: 'Teal',
    },
    {
      id: 'theme-rose',
      name: 'Rose',
    },
    {
      id: 'theme-purple',
      name: 'Purple',
    },
    {
      id: 'theme-amber',
      name: 'Amber',
    },
  ],
  routes: {
    contacts: 'contacts',
    verification: 'verification',
    login: 'login',
    callback: 'implicit/callback',
    protected: 'protected',
    error404: '404',
  },
  endpoints: {
    contacts: '/v1/contact',
    countries: '/v1/country',
    auth: '/v1/auth',
  },
  oidc: {
    clientId: '0oai70hrhwUA95Pkx5d7',
    issuer: 'https://dev-76988434.okta.com/oauth2/default',
    callback: '/implicit/callback',
    redirectUri: 'http://localhost:4200/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
  },
  // frontendUrl: 'http://localhost:4200',
  frontendUrl: `${window.location.origin}`,

  /*oidc: {
         clientId: `0oara9cdumHG2TqiW1t7`,
         issuer: `https://afreximbank.okta.com/oauth2/default`,
         callback: '/implicit/callback',
         redirectUri: 'https://tbs-dev.dev.afreximbank.net/implicit/callback',
         scopes: ['openid', 'profile', 'email'],
     },
    frontendUrl: 'https://tbs-dev.dev.afreximbank.net',*/
  /*oidc: {
        clientId: `0oalmlbkmb0CSemn21t7`,
        issuer: `https://afreximbank.okta.com/oauth2/default`,
        callback: '/implicit/callback',
        redirectUri: 'https://afreximbank.okta.com/implicit/callback',
        scopes: ['openid', 'profile', 'email'],
    },
    frontendUrl: 'https://ops-workflow.dev.afreximbank.net',*/
};
