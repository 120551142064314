<div
  [matTooltip]="user?.firstname ? user?.firstname + ' ' + user?.lastname : user?.name"
  class="flex items-center justify-center min-w-10 w-10 h-10 m-0.5 rounded-full text-md ring-2 mr-4 border-2 ring-white uppercase bg-[#6067A3] text-white dark:bg-gray-700 dark:text-gray-200"
  [class]="className"
>
  <ng-container *ngIf="user?.firstname">
    {{ user?.firstname?.charAt(0) }}{{ user?.lastname?.charAt(0) }}
  </ng-container>

  <ng-container *ngIf="!user?.firstname">
    {{ user?.name?.split(' ')[0]?.charAt(0) }}{{ user?.name?.split(' ')[1]?.charAt(0) }}
  </ng-container>
</div>
