import moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { appConfig } from '../../core/config/app.config';
import { environment } from '../../../assets/environments/environment';

@Injectable()
export class SSOAuthService {
  private authUrl;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.authUrl = appConfig.endpoints.auth;
  }

  private setSession(authResult: any): void {
    const expiresAt = authResult.data.expiresIn;
    localStorage.setItem('permissions', JSON.stringify(authResult.data.profile));
    localStorage.setItem('id_token', authResult.data.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  login(username: string, password: string): any {
    const data = { email: username, password };
    const url = `${environment.apiUrl}/api/v1/auth/login`;

    return this.http.post<any>(url, data).pipe(
      map((result) => {
        if (result && result.data && result.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setSession(result);
          localStorage.setItem('currentUser', JSON.stringify(result.data));
        }

        return result;
      })
    );
  }

  checkEmail(email: string): any {
    const data = { email };
    const url = `${environment.apiUrl}/ms/api/v1/auth/check-email`;
    return this.http.post<any>(url, data);
  }

  emailSignIn(hash: string, code: string): any {
    const data = { hash, code };
    const url = `${environment.apiUrl}/ms/api/v1/auth/email-signin`;

    return this.http.post<any>(url, data);
  }

  oauth(data: any) {
    const isStaff = JSON.parse(localStorage.getItem('isStaff')) as boolean;
    const url = `${environment.apiUrl}/ms/api/v1/auth/oauth/okta-v3?isStaff=${isStaff}`;

    return this.http.post(url, data);
    // .pipe(tap(() => localStorage.removeItem('isStaff')));
  }

  getUsers(query): Observable<any[]> {
    const params = this.getParams(query);
    const url = `${environment.apiUrl}/ms/api/v1/auth/user`;

    return this.http.get<any[]>(url, { params });
  }

  public isLoggedIn(): any {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut(): any {
    return !this.isLoggedIn();
  }

  getUserPermissions(): any {
    const permissions = localStorage.getItem('permissions');
    return JSON.parse(permissions);
  }

  getCurrentUser(): any {
    return localStorage.getItem('currentUser');
  }

  getExpiration(): any {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  logout(): any {
    // remove user from local storage to log user out
    return this.http.get<any>(`${environment.apiUrl}/v1/auth/logout`).pipe(
      map((result) => {
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('permissions');

        return result;
      })
    );
  }

  public getParams = (query: any) => {
    let params = new HttpParams()
      .set('page', `${query.page + 1}`)
      .set('limit', `${query.per_page}`)
      .set('sort', `${query.sort}`)
      .set('order', `${query.order}`);
    if (query.q) {
      params = params.set('search', query.q);
    }

    if (query.start) {
      params = params.set('start', `${query.start}`);
    }

    if (query.end) {
      params = params.set('end', `${query.end}`);
    }

    if (query.filter) {
      params = params.set('filter', `${query.filter}`);
    }

    if (query.department) {
      params = params.set('department', `${query.department}`);
    }

    if (query.unit) {
      params = params.set('unit', `${query.unit}`);
    }

    if (query.group) {
      params = params.set('group', `${query.group}`);
    }

    if (query.status) {
      params = params.set('status', `${query.status}`);
    }

    if (query.enabled) {
      params = params.set('enabled', `${query.enabled}`);
    }

    return params;
  };
}
