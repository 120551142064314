import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RbacDirective } from './rbac.directive';

@NgModule({
  declarations: [RbacDirective],
  imports: [CommonModule],
  exports: [RbacDirective],
})
export class RbacModule {}
