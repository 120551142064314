import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
  compactNavigation,
  defaultNavigation,
  futuristicNavigation,
  horizontalNavigation,
} from 'app/mock-api/common/navigation/data';
import { CountApiService } from 'app/services/count-api.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationMockApi {
  private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
  private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
  private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
  private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

  constructor(
    private _fuseMockApiService: FuseMockApiService,
    private _countApiService: CountApiService // Inject CountApiService
  ) {
    // Register Mock API handlers
    this.registerHandlers();
    // Subscribe to count changes
    this._subscribeToCount();
  }

  private _subscribeToCount(): void {
    this._countApiService.count$.subscribe((count) => {
      // Find and update the "Initiated Requests" item in the navigation
      this._updateInitiatedRequestCount(count);
    });
  }

  private _updateInitiatedRequestCount(count: number): void {
    // Update count in each navigation type
    const updateCountInNavigation = (navigation: FuseNavigationItem[]) => {
      const initiatedRequestItem = navigation.find(
        (item) => item.id === 'workflow.initiated-requests'
      );
      if (initiatedRequestItem) {
        initiatedRequestItem.title = `Initiated Requests (${count})`;
      }
    };

    updateCountInNavigation(this._compactNavigation);
    updateCountInNavigation(this._defaultNavigation);
    updateCountInNavigation(this._futuristicNavigation);
    updateCountInNavigation(this._horizontalNavigation);
  }

  registerHandlers(): void {
    this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
      // Ensure navigation children are updated
      this._updateChildrenNavigation();
      return [
        200,
        {
          compact: cloneDeep(this._compactNavigation),
          default: cloneDeep(this._defaultNavigation),
          futuristic: cloneDeep(this._futuristicNavigation),
          horizontal: cloneDeep(this._horizontalNavigation),
        },
      ];
    });
  }

  private _updateChildrenNavigation(): void {
    // Copy children from default navigation to other navigation types
    this._compactNavigation.forEach((compactNavItem) => {
      this._defaultNavigation.forEach((defaultNavItem) => {
        if (defaultNavItem.id === compactNavItem.id) {
          compactNavItem.children = cloneDeep(defaultNavItem.children);
        }
      });
    });
    this._futuristicNavigation.forEach((futuristicNavItem) => {
      this._defaultNavigation.forEach((defaultNavItem) => {
        if (defaultNavItem.id === futuristicNavItem.id) {
          futuristicNavItem.children = cloneDeep(defaultNavItem.children);
        }
      });
    });
    this._horizontalNavigation.forEach((horizontalNavItem) => {
      this._defaultNavigation.forEach((defaultNavItem) => {
        if (defaultNavItem.id === horizontalNavItem.id) {
          horizontalNavItem.children = cloneDeep(defaultNavItem.children);
        }
      });
    });
  }
}
