import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { SSOAuthService } from './shared/services/sso-auth.service';
import { ToastrModule } from 'ngx-toastr';
import { AvatarModule } from 'ngx-avatar';
import { OktaAppService } from './shared/services/okta-app.service';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};
const oktaConfig = {
  issuer: `${appConfig.oidc.issuer}`,
  clientId: `${appConfig.oidc.clientId}`,
  redirectUri: `${appConfig.frontendUrl}${appConfig.oidc.callback}`,
};

const avatarColors = ['#FFB6C1', '#2c3e50', '#95a5a6', '#f39c12', '#1abc9c'];

@NgModule({
  declarations: [AppComponent, CallbackComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    ToastrModule.forRoot(),
    AvatarModule.forRoot({ colors: avatarColors }),
    NgApexchartsModule,
    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),
    // Core module of your application
    CoreModule,
    // Layout module of your application
    LayoutModule,
    NgSelectModule,
    MatDialogModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    { provide: OKTA_CONFIG, useValue: oktaConfig },
    SSOAuthService,
    OktaAppService,
  ],
})
export class AppModule {}
