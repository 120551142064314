<div class="flex flex-col h-auto shadow rounded-2xl overflow-hidden bg-card">
  <div class="flex flex-col">
    <div class="flex flex-row justify-between items-center px-4 mt-5">
      <div
      class="text-lg text-titleCardColor text-start w-widthTitle font-bold  tooltip"
        [title]="serviceRequest?.mission?.title"
      >
        {{ serviceRequest?.mission?.title | truncate: 25}}
      </div>
      <div class="" >
        <app-avatar [user]="serviceRequest?.assignee"></app-avatar>
      </div>
    </div>

    <div
      class="flex flex-row space-x-4  text-lg text-titleCardColor pr-2 pl-4 pb-3 pt-3"
    >
      <div class="flex items-center">
        <img
          class="w-6 mr-2"
          src="assets/icons/mdi_calendar-arrow-right.svg"
          alt="Logo image"
        />
        <span class="leading-none">{{
          serviceRequest?.mission?.startsAt | date : "d MMM YY"
        }}</span>
      </div>

      <div class="flex items-center">
        <img
          class="w-6 mx-2"
          src="assets/icons/mdi_calendar-arrow-left.svg"
          alt="Logo image"
        />
        <span class="leading-none">{{
          serviceRequest?.mission?.endsAt | date : "d MMM YY"
        }}</span>
      </div>
    </div>

    <div
      class="flex items-center pl-2"
      *ngFor="let location of serviceRequest?.mission?.locations"
    >
      <mat-icon
        role="img"
        class="mat-icon -mt-3 notranslate icon-size-5 mx-2 mat-icon-no-color"
        svgIcon="heroicons_solid:location-marker"
      ></mat-icon>
      <span class="leading-none font-bold text-titleCardColor mb-3">
        {{ location?.city }} - {{ location?.country }}</span
      >
    </div>

    <div class="border-solid border-2 border-cardTitleColor"></div>

    <div class="px-2">
      <app-item-tasks [sr]="serviceRequest"></app-item-tasks>
    </div>
  </div>

  <div class="flex flex-col w-full mt-auto">
    <div class="relative h-0.5">
      <div
        class="z-10 absolute inset-x-0 h-6 -mt-3"
        [matTooltip]="
          (100 * serviceRequestsLength) / serviceRequest?.tasks?.length
            | percent
        "
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'-mb-0.5'"
      ></div>

      <mat-progress-bar
        class="h-0.5"
        [value]="serviceRequest?.tasks | average"
      ></mat-progress-bar>
    </div>

    <div class="px-6 py-4 text-right bg-gray-50 dark:bg-transparent">
      <a
        mat-stroked-button
        [routerLink]="['/apps/service-request/', serviceRequest?.id]"
      >
        <span class="inline-flex items-center">
          <ng-container>
            <ng-container>
              <span class="text-[#6067A3]">View</span>
            </ng-container>
          </ng-container>

          <mat-icon
            class="ml-1.5 icon-size-5"
            [svgIcon]="'heroicons_solid:arrow-sm-right'"
          ></mat-icon>
        </span>
      </a>
    </div>
  </div>
</div>
