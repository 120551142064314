<div class="flex items-center p-1 mt-4">
  <div
    class="flex items-center justify-center mr-4 w-10 h-10 m-0.5 rounded-full text-md ring-2 ring-white pr- uppercase bg-[#6067A3] text-white dark:bg-gray-700 dark:text-gray-200"
  >
    {{ sr?.staff?.firstname?.charAt(0) }}{{ sr?.staff?.lastname?.charAt(0) }}
  </div>
  <div class="flex flex-col">
    <span class="font-bold leading-none text-cardTitleColor text-md"
      >{{ sr?.staff?.firstname }} {{ sr?.staff?.lastname }}</span
    >
    <span class="text-secondary leading-none mt-1 text-sm">
      {{ sr?.staff?.title }} ({{ sr?.staff?.department }})
    </span>
  </div>
</div>

<!-- Task content -->
<mat-list class="min-h-80 max-h-80 overflow-auto" *ngIf="sr?.tasks?.length > 4">
  <mat-list-item
    class="my-2 h-12 border-b-2 rounded-sm"
    *ngFor="let task of sr.tasks; let first = first"
  >
    <div class="absolute inset-y-0 left-1 mb-2">
      <div class="relative py-3 flex items-center h-full pl-5">
        <!-- Selected indicator -->
        <ng-container [ngSwitch]="task.name">
          <div
            *ngSwitchCase="'Acknowledge receipt of mission'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-orange-400"
          ></div>
          <div
            *ngSwitchCase="'Ticket'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-cyan-400"
          ></div>
          <div
            *ngSwitchCase="'Hotel Options & Confirmation on Logistics '"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-green-500"
          ></div>
          <div
            *ngSwitchCase="'Hotel'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-green-500"
          ></div>
          <div
            *ngSwitchCase="'Car At Disposal (Optional)'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-purple-500"
          ></div>
          <div
            *ngSwitchCase="'Car At Disposal (Mandatory)'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-purple-500"
          ></div>
          <div
            *ngSwitchCase="'Transit Hotel '"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-green-500"
          ></div>

          <div
            *ngSwitchCase="'Outbound Transfer from duty station to Airport'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-purple-500"
          ></div>

          <div
            *ngSwitchCase="'Outbound Meet & Assist(CAI Airport)'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-cyan-400"
          ></div>
          <div
            *ngSwitchCase="'Transfer from Airport to Hotel'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-purple-500"
          ></div>
          <div
            *ngSwitchCase="'Transfer from Hotel to Airport'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-purple-500"
          ></div>
          <div
            *ngSwitchCase="'Inbound Meet & Assist (Cairo Airport) '"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-cyan-400"
          ></div>
          <div
            *ngSwitchCase="
              'Transfer from Hotel to Airport(Mercedes S or E or equivalent)'
            "
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-yellow-500"
          ></div>
          <div
            *ngSwitchCase="'VIP Meet & Greet at destination airport'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-yellow-500"
          ></div>
          <div
            *ngSwitchCase="'Inbound transfer from the airport to duty station'"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-cyan-400"
          ></div>
          <div
            *ngSwitchCase="'Survey '"
            src="../../assets/icons/description_black_24dp%202.svg"
            class="z-10 absolute -top-px left-0 -bottom-px flex flex-0 w-1 bg-orange-500"
          ></div>
        </ng-container>

        <!-- Drag handle -->
        <div
          class="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
          cdkDragHandle
        >
          <mat-icon
            class="icon-size-5 text-hint"
            [svgIcon]="'heroicons_solid:menu'"
          ></mat-icon>
        </div>

        <button class="mr-2 -ml-4 sm:-ml-2.5 leading-none" mat-icon-button>
          <ng-container [ngSwitch]="task.name">
            <img
              *ngSwitchCase="'Acknowledge receipt of mission'"
              src="../../assets/icons/fluent_contact-card-24-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Ticket'"
              src="../../assets/icons/fluent_airplane-take-off-24-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Hotel Options & Confirmation on Logistics '"
              src="../../assets/icons/carbon_hotel.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Hotel'"
              src="../../assets/icons/carbon_hotel.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Car At Disposal (Optional)'"
              src="../../assets/icons/fluent_vehicle-car-profile-ltr-16-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Car At Disposal (Mandatory)'"
              src="../../assets/icons/fluent_vehicle-car-profile-ltr-16-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Transit Hotel '"
              src="../../assets/icons/carbon_hotel.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Outbound Transfer from duty station to Airport'"
              src="../../assets/icons/fluent_vehicle-car-profile-ltr-16-regular.svg"
              class="icon-size-6"
            />

            <img
              *ngSwitchCase="'Outbound Meet & Assist(CAI Airport)'"
              src="../../assets/icons/mdi_help-outline.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Transfer from Airport to Hotel'"
              src="../../assets/icons/fluent_vehicle-car-profile-ltr-16-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Transfer from Hotel to Airport'"
              src="../../assets/icons/fluent_vehicle-car-profile-ltr-16-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Inbound Meet & Assist (Cairo Airport) '"
              src="../../assets/icons/fluent_airplane-take-off-24-regular.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="
                'Transfer from Hotel to Airport(Mercedes S or E or equivalent)'
              "
              src="../../assets/icons/ri_vip-crown-2-line.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'VIP Meet & Greet at destination airport'"
              src="../../assets/icons/ri_vip-crown-2-line.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="
                'Inbound transfer from the airport to duty station'
              "
              src="../../assets/icons/mdi_help-outline.svg"
              class="icon-size-6"
            />
            <img
              *ngSwitchCase="'Survey '"
              src="../../assets/icons/description_black_24dp%202.svg"
              class="icon-size-6"
            />
          </ng-container>
        </button>

        <!-- Task link -->
        <a
          class="flex flex-auto items-center min-w-0 h-full pr-1"
          [routerLink]=""
        >
          <!-- Title & Placeholder -->
          <div class="flex flex-col">
            <span
              class="font-bold leading-none text-gray-600 w-22 break-all text-sm"
              >{{ task?.name }}</span
            >
            <span class="text-secondary leading-none mt-1 text-sm"
              >Due date :{{ task?.createdAt | date : "mediumDate" }}</span
            >
          </div>
          <div class="flex-auto mr-2 truncate"></div>
          <div class="flex-auto mr-2 truncate"></div>
          <!-- Priority -->

          <!-- Due date -->
          <div
            class="w-4 h-4 mt-2 text-[12px] font-bold"
            [ngClass]="{
              'text-orange-500': task?.status !== taskStatus.COMPLETED,
              'text-green-500':
                task?.status === taskStatus.COMPLETED ||
                task?.status === taskStatus.COMPLETE
            }"
          >
            {{
              task?.status === taskStatus.COMPLETED ||
              task.status === taskStatus.COMPLETE
                ? "100%"
                : "0%"
            }}
          </div>
        </a>
      </div>
    </div>
  </mat-list-item>
</mat-list>
